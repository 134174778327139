import React, { useEffect, useState } from "react";
import { merchBankDetails, verifyMerchBank } from "../../urls"; // Add verifyMerchBank URL
import API from "../../components/api2";
import { changePage } from "../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { circles } from "../../images";
import PaginationTable from "../../components/PaginationTable";
import Filter2 from "../../components/Filter2";
import Swal from "sweetalert2";

const AllAccounts = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 14 });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBankList({ initial: true });
  }, []);

  const fetchBankList = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }
    API({
      ...merchBankDetails,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          console.log("test");
        } else {
          if (resolve) {
            resolve(res?.data?.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res?.data?.data?.table_data,
              ]);
            } else {
              setFilteredTable(res?.data?.data?.table_data);
              setTotal(res.data.data.total_rows);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error("Error fetching accounts: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const handleVerify = (res_id) => {
    console.log(res_id);

    API({
      ...verifyMerchBank,
      body: {
        res_id: res_id,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          fetchBankList();
        });
      },
      onError: (error) => {
        Swal.fire({
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const changePageFunc = ({ targetPage }) => {
    dispatch(
      changePage({
        page: targetPage,
      })
    );
    navigate("/", {});
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchBankList({ append: true });
    }
  }, [pagination]);

  const columns = [
    {
      Header: "Verify",
      Cell: ({ row }) => {
        const { verify, res_id } = row.original;
        return verify ? (
          <span style={{ color: "green", fontWeight: "bold" }}>Verified</span>
        ) : (
          <button
            style={{
              backgroundColor: "#194280",
              color: "white",
              border: "none",
              padding: "5px 10px",
              fontSize: "10px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => handleVerify(res_id)}
          >
            Verify
          </button>
        );
      },
    },
    {
      Header: "VPA Address",
      accessor: "vpa_address",
    },
    {
      Header: "IFSC",
      accessor: "ben_ifsc",
    },
    {
      Header: "Account Number",
      accessor: "ben_account_number",
    },
    {
      Header: "Beneficiary Name",
      accessor: "ben_name",
    },

    {
      Header: "Merchant ID",
      accessor: "merchnt_id",
    },

    {
      Header: "Created By",
      accessor: "created_by",
    },
    {
      Header: "Created On",
      accessor: "created_on",
    },
  ];

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            All Merchant Accounts
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchBankList}
          loading={loading}
          include_excel="false"
          filters={[]}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              changePageFunc({ targetPage: "addAccount" });
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD ACCOUNT"}
            </p>
          </div>
        </Filter2>
        <PaginationTable
          totalRows={total}
          columns={columns}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
        />
      </div>
    </>
  );
};

export default AllAccounts;
