import React, { useEffect, useState } from "react";
import {
  airtelDth,
  serviceCircle,
  dishTv,
  sunDirect,
  tataSky,
  videocon,
  activeIcon,
  suspendIcon,
} from "../../../images";
import { getMobOperator, dthPlans, dthRecharge } from "../../../urls";
import API from "../../../components/api2";
import Select, { components } from "react-select";
import loaderGif from "../../../gif/buffer.gif";
import Swal from "sweetalert2";

const { Option } = components;

const DthRecharge = ({ serviceName }) => {
  const [loader, setLoader] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [formData, setFormData] = useState({
    operator_name: "",
    operator_id: "",
    canumber: "",
    transaction_amount: "",
    upin: "",
    dth_operator_image: "",
  });

  const [operatorVisible, setOperatorVisible] = useState(false);
  const [operators, setOperators] = useState([]);
  const [plansData, setPlansData] = useState({});

  useEffect(() => {
    setIsButtonDisabled(
      !formData.operator_name ||
        !formData.canumber ||
        !formData.transaction_amount
    );
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getImageUrl = (name) => {
    const imageUrls = {
      "Tata Sky": tataSky,
      Videocon: videocon,
      Airteldth: airtelDth,
      "Dish TV": dishTv,
      "Sun Direct": sunDirect,
    };
    return imageUrls[name] || "";
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      operator_id: selectedOption.value,
      operator_name: selectedOption.label,
      dth_operator_image: selectedOption.imageUrl,
    }));
    setOperatorVisible(true);
    setLoader(true);
    setTimeout(() => setLoader(false), 1000);
  };
  const CustomOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.imageUrl}
        alt={props.data.label}
        style={{ width: "24px", marginRight: "8px" }}
      />
      {props.data.label}
    </Option>
  );

  // get operator
  useEffect(() => {
    const getOperators = () => {
      API({
        ...getMobOperator,
        onSuccess: (res) => {
          const prepaidOperators = res.data.data
            .filter((operator) => operator.category === "DTH")
            .map((operator) => ({
              value: operator.id,
              label: operator.name,
              imageUrl: getImageUrl(operator.name),
            }));
          setOperators(prepaidOperators);
        },
        onError: (error) => {
          console.log("error fetching operators", error);
        },
      });
    };
    getOperators();
  }, []);

  // get plans
  useEffect(() => {
    const checkPlans = () => {
      if (
        formData.canumber &&
        formData.canumber.length >= 10 &&
        formData.operator_name
      ) {
        setLoader(true);
        API({
          ...dthPlans,
          body: {
            canumber: parseInt(formData.canumber),
            operator: formData.operator_name,
          },
          onSuccess: (res) => {
            setPlansData(res.data.data);
            setLoader(false);
          },
          onError: (error) => {
            console.log("error getting dth plans", error);
            setLoader(false);
          },
        });
      }
    };
    checkPlans();
  }, [formData.canumber, dthPlans]);

  const { status } = plansData;

  const handlePriceClick = (price) => {
    setFormData((prevState) => ({
      ...prevState,
      transaction_amount: price,
    }));
  };

  // operator click
  const handleOperatorClick = (operatorName) => {
    const imageUrl = getImageUrl(operatorName);
    setFormData({
      ...formData,
      operator_name: operatorName,
      dth_operator_image: imageUrl,
    });
  };

  // handle recharge
  const handleRecharge = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Confirm Recharge",
      text: "Are you sure you want to proceed with the recharge?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((confirmation) => {
      if (confirmation.isConfirmed) {
        Swal.fire({
          title: "Enter UPIN",
          input: "password",
          inputAttributes: {
            maxlength: "6",
            autocapitalize: "off",
            autocorrect: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          inputValidator: (value) => {
            if (!value || value.length !== 6) {
              return "UPIN must be a 6-digit number";
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setLoader(true);
            const { dth_operator_image, ...payload } = formData;
            payload.operator_id = parseInt(payload.operator_id, 10);
            payload.upin = String(result.value);
            API({
              ...dthRecharge,
              body: payload,
              onSuccess: (res) => {
                setLoader(false);
                Swal.fire({
                  title: `${res.data.message}`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              },
              onError: (error) => {
                setLoader(false);
                Swal.fire({
                  icon: "error",
                  title: `${error.response.data.message}`,
                });
              },
            });
          }
        });
      }
    });
  };
  return (
    <>
      {/* recharge box  */}
      <div
        className="position-relative mx-5 mb-3 px-2"
        style={{
          border: "1px solid rgba(217, 217, 217, 1)",
          boxShadow: "0px 4px 40px 10px rgba(0, 0, 0, 0.15)",
          borderRadius: "20px",
        }}
      >
        <img
          style={{
            position: "absolute",
            right: "0",
            top: "0",
            zIndex: "-1",
          }}
          src={serviceCircle}
          alt="circle"
        />
        <div className="row">
          {/* recharge form  */}

          <div
            style={{ borderRight: "1px solid #D9D9D9" }}
            className="col-3 py-3"
          >
            <div
              className="p-4"
              style={{
                backgroundColor: "rgba(25, 66, 128, 1)",
                borderRadius: "20px",
              }}
            >
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "white",
                }}
                className="mb-1"
              >
                {serviceName}
              </p>

              <form className="mt-2 mx-2" onSubmit={handleRecharge}>
                <label
                  htmlFor="operator_name"
                  style={{
                    color: "#ADACAC",
                    transition: "all 0.4s ease-in-out",
                    fontSize: "14px",
                    opacity: formData.operator_name ? 1 : 0,
                  }}
                  className="ms-2 trans_text"
                >
                  DTH Operator
                </label>
                <Select
                  id="operator_name"
                  name="operator_name"
                  className="recharge_select trans_text"
                  placeholder="DTH Operator"
                  value={
                    formData.operator_name
                      ? { label: formData.operator_name }
                      : null
                  }
                  options={operators}
                  components={{
                    Option: CustomOption,
                  }}
                  onChange={handleSelectChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "transparent",
                      borderWidth: "0 0 1.5px 0",
                      borderBottom: formData.operator_name
                        ? "1.5px solid white"
                        : "1.5px solid #ADACAC",
                      width: "100%",
                      color: formData.operator_name ? "white" : "#ADACAC",
                      borderRadius: "0px",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                        boxShadow: "none",
                      },
                      marginTop: "-8px",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                  }}
                />
                {operatorVisible && (
                  <>
                    <label
                      htmlFor="canumber"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        fontSize: "14px",
                        opacity: formData.canumber ? 1 : 0,
                        marginTop: "10px",
                      }}
                      className="ms-2 trans_text"
                    >
                      Subscriber Number
                    </label>
                    <input
                      type="number"
                      id="canumber"
                      name="canumber"
                      value={formData.canumber}
                      onChange={handleInputChange}
                      placeholder="Subscriber Number"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.canumber
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      className="trans_text"
                    />

                    <label
                      htmlFor="transaction_amount"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        fontSize: "14px",
                        opacity: formData.transaction_amount ? 1 : 0,
                        marginTop: "10px",
                      }}
                      className="ms-2 trans_text"
                    >
                      Amount
                    </label>
                    <input
                      type="number"
                      id="transaction_amount"
                      name="transaction_amount"
                      value={formData.transaction_amount}
                      onChange={handleInputChange}
                      placeholder="Amount"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.transaction_amount
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      className="trans_text"
                    />
                  </>
                )}
                <div className="text-center mt-4">
                  <button
                    className="px-4 py-2 trans_text"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      border: "none",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    type="submit"
                    disabled={isButtonDisabled}
                  >
                    Proceed to recharge
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* plans  */}
          <div className="col-9 py-4">
            {formData.operator_name &&
              (loader ? (
                <img
                  className="position-absolute"
                  style={{
                    height: "60px",
                    width: "60px",
                    left: "60%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  src={loaderGif}
                  alt="Loading..."
                />
              ) : (
                <>
                  <div
                    className="pb-1 ms-4"
                    style={{
                      borderBottom: "1.5px solid #D9D9D9",
                      marginRight: "55px",
                    }}
                  >
                    <h2
                      className="d-inline-block trans_text"
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        borderBottom: "2px solid #194280",
                      }}
                    >
                      Browse Detail
                    </h2>
                  </div>
                  <div className="row mt-3 h-100">
                    <div
                      style={{
                        borderRight: "1px solid rgba(217, 217, 217, 1)",
                      }}
                      className="col-6 h-100"
                    >
                      <div className="ms-4">
                        <img
                          src={formData.dth_operator_image}
                          alt={formData.operator_name}
                          style={{
                            height: "60px",
                            width: "60px",
                          }}
                        />
                        <p
                          style={{ fontWeight: "500", fontSize: "17px" }}
                          className="mb-0 mt-3 trans_text"
                        >
                          Customer Name
                        </p>
                        <div
                          style={{
                            backgroundColor: "rgba(233, 239, 247, 1)",
                            borderBottom: "1px solid rgba(217, 217, 217, 1)",
                            height:
                              formData.canumber &&
                              formData.canumber.length >= 10
                                ? "50px"
                                : "40px",
                            fontWeight: "500",
                          }}
                          className="mt-2 pt-2 ps-2"
                        >
                          <p className="mb-0">
                            {plansData.customerName
                              ? plansData?.customerName
                              : "N/A"}
                          </p>
                          {formData.canumber &&
                            formData.canumber.length >= 10 && (
                              <div className="d-flex align-items-center pe-2 justify-content-end">
                                <img
                                  src={
                                    status === "Active"
                                      ? activeIcon
                                      : suspendIcon
                                  }
                                  alt="status"
                                />
                                <p
                                  className="mb-0 ps-1"
                                  style={{
                                    color: "rgba(105, 102, 102, 1)",
                                    fontSize: "14px",
                                  }}
                                >
                                  {status === "Active"
                                    ? "Active Plan"
                                    : "Low Balance"}
                                </p>
                              </div>
                            )}
                        </div>
                        {formData.canumber &&
                          formData.canumber.length >= 10 && (
                            <div
                              style={{
                                background:
                                  "linear-gradient(90deg, #8C37A6 0%, #684BDB 100%)",
                                fontWeight: "500",
                                fontSize: "20px",
                                color: "white",
                                borderRadius: "10px",
                                cursor: "pointer",
                              }}
                              className="mt-3 py-2 text-center col-5"
                              onClick={() =>
                                handlePriceClick(plansData.MonthlyRecharge)
                              }
                            >
                              Monthly Plan <br /> ₹{" "}
                              {plansData.MonthlyRecharge
                                ? plansData?.MonthlyRecharge
                                : "0"}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-6 h-100">
                      <div className="ms-4">
                        <p
                          style={{
                            fontWeight: "500",
                            fontSize: "17px",
                            marginTop: "76px",
                          }}
                          className="mb-0 trans_text"
                        >
                          Balance
                        </p>
                        <div
                          style={{
                            backgroundColor: "rgba(233, 239, 247, 1)",
                            borderBottom: "1px solid rgba(217, 217, 217, 1)",
                            height:
                              formData.canumber &&
                              formData.canumber.length >= 10
                                ? "50px"
                                : "40px",
                            fontWeight: "500",
                          }}
                          className="mt-2 pt-3 ps-2"
                        >
                          {plansData.Balance ? plansData?.Balance : "N/A"}
                        </div>
                        {formData.canumber &&
                          formData.canumber.length >= 10 && (
                            <p
                              style={{
                                textDecoration: "underline",
                                color: "rgba(105, 102, 102, 1)",
                                fontSize: "17px",
                              }}
                              className="mb-0 mt-2 trans_text"
                            >
                              Renew in {plansData?.NextRechargeDate}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>

      {/* choose operator  */}
      <div
        style={{
          border: "1px solid rgba(217, 217, 217, 1)",
          boxShadow: "0px 4px 40px 10px rgba(0, 0, 0, 0.15)",
          borderRadius: "20px",
        }}
        className="my-3 mx-5 p-4"
      >
        <h2 style={{ fontWeight: "500", fontSize: "20px" }}>
          Choose an Operator
        </h2>
        <div className="d-flex align-items-center mt-4">
          <div
            onClick={() => handleOperatorClick("Tata Sky")}
            className="text-center"
          >
            <img
              style={{ cursor: "pointer", pointerEvents: "all" }}
              src={tataSky}
              alt="tataSKy"
            />
            <p
              style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
              className="mb-0 mt-2 trans_text"
            >
              Tata Sky
            </p>
          </div>
          <div
            onClick={() => handleOperatorClick("Videocon D2H")}
            className="ms-5 text-center"
          >
            <img
              style={{ cursor: "pointer", pointerEvents: "all" }}
              src={videocon}
              alt="videocon"
            />
            <p
              style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
              className="mb-0 mt-2 trans_text"
            >
              Videocon d2h
            </p>
          </div>
          <div
            onClick={() => handleOperatorClick("Airtel Digital TV")}
            className="ms-5 text-center"
          >
            <img
              style={{ cursor: "pointer", pointerEvents: "all" }}
              src={airtelDth}
              alt="airtelDth"
            />
            <p
              style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
              className="mb-0 mt-2 trans_text"
            >
              Airtel Digital TV
            </p>
          </div>
          <div
            onClick={() => handleOperatorClick("Dish TV")}
            className="ms-5 text-center"
          >
            <img
              style={{ cursor: "pointer", pointerEvents: "all" }}
              src={dishTv}
              alt="dishTv"
            />
            <p
              style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
              className="mb-0 mt-2 trans_text"
            >
              Dish TV
            </p>
          </div>
          <div
            onClick={() => handleOperatorClick("Sun Direct")}
            className="ms-5 text-center"
          >
            <img
              style={{ cursor: "pointer", pointerEvents: "all" }}
              src={sunDirect}
              alt="sunDirect"
            />
            <p
              style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
              className="mb-0 mt-2 trans_text"
            >
              Sun Direct
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DthRecharge;
