import React, { useState, useEffect } from "react";
import API from "../../components/api2";
import { addMerchBank, dmtBankList, dmtIfscList } from "../../urls";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { circles } from "../../images";
import { useNavigate } from "react-router-dom";
import InputForm from "../../components/InputForm";
import Swal from "sweetalert2";
import { changePage } from "../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Select from "react-select";

const AddAccount = () => {
  const defaultPageData = {
    account_type: "Bank Account",
    ben_name: "",
    ben_bank_name: "",
    ben_ifsc: "",
    ben_account_number: "",
    vpa_address: "",
  };

  const [pageData, setPageData] = useState(defaultPageData);
  const [bankList, setBankList] = useState([]);
  const [ifscList, setIfscList] = useState([]);
  const [ifscLoading, setIfscLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vpaError, setVpaError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTabChange = (type) => {
    setPageData((prev) => ({
      ...prev,
      account_type: type,
      ben_bank_name: "",
      ben_ifsc: "",
      ben_account_number: "",
      vpa_address: "",
    }));
    setVpaError("");
  };

  const pageDataFunc = (event) => {
    if (event && event.target) {
      const { id, value } = event.target;
      setPageData((prev) => ({ ...prev, [id]: value }));
      if (id === "vpa_address") {
        validateVpa(value);
      }
    }
  };

  const validateVpa = (vpa) => {
    const vpaRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+$/;
    if (vpa && !vpaRegex.test(vpa)) {
      setVpaError("Invalid VPA format. Example: john.doe@icici");
    } else {
      setVpaError("");
    }
  };

  const handleBankSelectChange = (selectedOption) => {
    setPageData((prev) => ({ ...prev, ben_bank_name: selectedOption.label }));
    setIfscList([]);
    setIfscLoading(true);

    Swal.fire({
      title: "Loading IFSC codes...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const loaderTimeout = setTimeout(() => {
      Swal.close();
      setIfscLoading(false);
    }, 10000);

    API({
      ...dmtIfscList,
      params: { bank: selectedOption.label },
      onSuccess: (res) => {
        const data = res.data.data;
        const ifscOptions = data.map((i) => ({
          value: i.ifsc,
          label: i.ifsc,
        }));
        setIfscList(ifscOptions);
      },
      onError: (error) => {
        console.error("Error fetching IFSC list:", error);
        clearTimeout(loaderTimeout);
        setIfscLoading(false);
        Swal.close();
      },
    });
  };

  const handleIfscSelectChange = (selectedOption) => {
    setPageData((prev) => ({ ...prev, ben_ifsc: selectedOption.value }));
  };

  // Fetch bank list
  useEffect(() => {
    API({
      ...dmtBankList,
      onSuccess: (res) => {
        const data = res.data.data;
        const bankOptions = data.map((bank) => ({
          value: bank.bank_id,
          label: bank.bank_name,
        }));
        setBankList(bankOptions);
      },
      onError: (error) => {
        console.error("Error fetching bank list:", error);
      },
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payload =
      pageData.account_type === "Bank Account"
        ? {
            account_type: "bank_account",
            ben_name: pageData.ben_name,
            // ben_bank_name: pageData.ben_bank_name,
            ben_ifsc: pageData.ben_ifsc,
            ben_account_number: pageData.ben_account_number,
          }
        : {
            account_type: "vpa",
            ben_name: pageData.ben_name,
            vpa_address: pageData.vpa_address,
          };

    API({
      ...addMerchBank,
      body: payload,
      onSuccess: (res) => {
        Swal.fire({ title: `${res.data.message}` }).then(() => {
          setPageData(defaultPageData);
          handleBack();
          setIsSubmitting(false);
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        setIsSubmitting(false);
      },
    });
  };

  const handleBack = () => {
    dispatch(
      changePage({
        page: "allAccounts",
      })
    );
    navigate("/");
  };

  const isFormComplete =
    pageData.account_type === "Bank Account"
      ? Object.values(pageData)
          .slice(1, 4)
          .every((value) => value.trim() !== "")
      : pageData.vpa_address.trim() !== "";

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-5 pt-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add Beneficiary
        </h2>

        {/* Tabs */}
        <div className="d-flex  my-3">
          {["Bank Account", "VPA"].map((type) => (
            <div
              key={type}
              onClick={() => handleTabChange(type)}
              className={`tab ${
                pageData.account_type === type ? "active-tab" : ""
              }`}
              style={{
                cursor: "pointer",
                padding: "10px 20px",
                borderBottom: `3px solid ${
                  pageData.account_type === type
                    ? "rgba(255, 159, 28, 1)"
                    : "transparent"
                }`,
                fontWeight: "500",
                color:
                  pageData.account_type === type
                    ? "rgba(255, 159, 28, 1)"
                    : "rgba(105, 102, 102, 1)",
                transition: "all 0.3s ease-in-out",
              }}
            >
              {type}
            </div>
          ))}
        </div>

        {/* Form Fields */}
        <div>
          {pageData.account_type === "Bank Account" && (
            <div className="row">
              <div className="col-6 mb-3">
                <InputForm
                  type="text"
                  placeholder="Beneficiary Name"
                  value={pageData.ben_name}
                  onChange={pageDataFunc}
                  id="ben_name"
                />
              </div>
              <div className="col-6">
                <label
                  htmlFor={"ben_bank_name"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  className="trans_text mt-2 mb-2"
                >
                  Beneficiary Bank Name
                </label>
                <Select
                  options={bankList}
                  value={bankList.find(
                    (option) => option.label === pageData.ben_bank_name
                  )}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      border: "0",
                      boxShadow: state.isFocused ? "none" : "none",
                    }),
                  }}
                  onChange={handleBankSelectChange}
                  isDisabled={ifscLoading}
                  placeholder="Select Bank"
                />
              </div>
              <div className="col-6">
                <label
                  htmlFor={"ben_ifsc"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  className="trans_text mt-2 mb-2"
                >
                  {" "}
                  Beneficiary IFSC Code
                </label>
                <Select
                  options={ifscList}
                  value={ifscList.find(
                    (option) => option.value === pageData.ben_ifsc
                  )}
                  onChange={handleIfscSelectChange}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      border: "0",
                      boxShadow: state.isFocused ? "none" : "none",
                    }),
                  }}
                  isDisabled={ifscLoading || !pageData.ben_bank_name}
                  placeholder="Select IFSC"
                />
              </div>
              <div className="col-6 mb-3">
                <InputForm
                  type="number"
                  placeholder="Beneficiary Account Number"
                  value={pageData.ben_account_number}
                  onChange={pageDataFunc}
                  id="ben_account_number"
                />
              </div>
            </div>
          )}

          {pageData.account_type === "VPA" && (
            <div className="row">
              <div className="col-6">
                <InputForm
                  type="text"
                  placeholder="Beneficiary Name"
                  value={pageData.ben_name}
                  onChange={pageDataFunc}
                  id="ben_name"
                />
              </div>
              <div className="col-6 mb-3">
                <InputForm
                  type="text"
                  placeholder="VPA Address"
                  value={pageData.vpa_address}
                  onChange={pageDataFunc}
                  id="vpa_address"
                />
                {vpaError && (
                  <small style={{ color: "red", fontSize: "12px" }}>
                    {vpaError}
                  </small>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between mt-3 mb-4">
          <Button onClick={handleBack} children={"Back"} />
          <Button
            onClick={onSubmit}
            type="submit"
            children={isSubmitting ? "Loading..." : "Submit"}
            disabled={!isFormComplete || isSubmitting || vpaError}
            style={{
              opacity: !isFormComplete || isSubmitting || vpaError ? 0.5 : 1,
              cursor:
                !isFormComplete || isSubmitting || vpaError
                  ? "not-allowed"
                  : "pointer",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AddAccount;
