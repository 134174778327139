import React, { useEffect, useState } from "react";
import { getAllUserGroups, createdByDropdown } from "../urls";
import API from "../components/api2";
import { actionIcon, circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import Filter2 from "../components/Filter2";

const AllUserGroups = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 14 });
  const [dropdown, setDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserGroups({ initial: true });
    fetchCreatedDropdown();
  }, []);

  const fetchUserGroups = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        if (key === "date") {
          const date = filterValues[key];
          if (Array.isArray(date)) {
            const fromDate = date[0];
            const toDate = date[1] || fromDate;
            filteredObject["date"] = `${fromDate} to ${toDate}`;
          } else {
            filteredObject["date"] = `${date} to ${date}`;
          }
        } else {
          filteredObject[key] = filterValues[key];
        }
      }
    }
    API({
      ...getAllUserGroups,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          // setActive(res.data.data.active_count);
          // setInActive(res.data.data.inactive_count);
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.helpdesk_user_groups,
              ]);
            } else {
              setFilteredTable(res.data.data.helpdesk_user_groups);
              setTotalRows(res.data.data.total_rows);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error("Error fetching user groups: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const fetchCreatedDropdown = () => {
    API({
      ...createdByDropdown,
      onSuccess: (res) => {
        setDropdown(res?.data?.data);
      },
      onError: (error) => {
        console.error("Error fetching dropdown: ", error);
      },
    });
  };

  const changePageFunc = ({ clickedService, targetPage }) => {
    if (clickedService) {
      dispatch(
        changePage({
          page: "userGroupInfo",
          service: clickedService,
        })
      );

      navigate("/", { state: { service: clickedService } });
    } else {
      dispatch(
        changePage({
          page: targetPage,
        })
      );
      navigate("/", {});
    }
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchUserGroups({ append: true });
    }
  }, [pagination]);

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            All User Groups
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchUserGroups}
          showExcelIcon={false}
          excelFileName={"User Groups Data"}
          loading={loading}
          filters={[
            {
              placeholder: "Group Name",
              id: "name",
              filterType: "input",
              inputType: "text",
            },
            {
              placeholder: "Created By",
              id: "created_by",
              filterType: "select",
              inputType: null,
              options: dropdown?.employees?.length
                ? dropdown?.employees?.map((item) => ({
                    value: item.id,
                    label: `${item.id}-${item.name}`,
                  }))
                : [{ value: "", label: "No options available" }],
            },
            {
              placeholder: "Created On",
              id: "date",
              filterType: "date",
            },
          ]}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              changePageFunc({ targetPage: "addUserGroup" });
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD User Group"}
            </p>
          </div>
        </Filter2>

        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() =>
                      changePageFunc({ clickedService: row.original })
                    }
                  />
                );
              },
            },
            { Header: "ID", accessor: "id" },
            { Header: "Name", accessor: "name" },
            { Header: "Created By", accessor: "created_by" },
            {
              Header: "Created On",
              accessor: "created_on",
              Cell: ({ value }) =>
                new Date(value).toLocaleString("en-IN", {
                  dateStyle: "medium",
                  timeStyle: "short",
                }),
            },
            {
              Header: "Updated On",
              accessor: "updated_on",
              Cell: ({ value }) =>
                new Date(value).toLocaleString("en-IN", {
                  dateStyle: "medium",
                  timeStyle: "short",
                }),
            },
            { Header: "Updated By", accessor: "updated_by" },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
          totalRows={totalRows}
        />
      </div>
    </>
  );
};

export default AllUserGroups;
