import React, { useEffect, useState } from "react";
import {
  selectedDropdown,
  merchantDropdownReport,
  walletRechgReport,
} from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import Filter2 from "../components/Filter2";
import Swal from "sweetalert2";

const WalletRechargeReport = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 14 });
  const [filters, setFilters] = useState({});
  const [dropdown, setDropdown] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedRd, setSelectedRd] = useState(null);
  const [selectedAd, setSelectedAd] = useState(null);
  const [selectedLd, setSelectedLd] = useState(null);
  const [selectedAg, setSelectedAg] = useState(null);
  const [rdOptions, setRdOptions] = useState([]);
  const [adOptions, setAdOptions] = useState([]);
  const [ldOptions, setLdOptions] = useState([]);
  const [agOptions, setAgOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  async function fetchApplicationExcel({
    filterValues = {},
    excludedKeys = [
      "brand_id",
      "rd_merchant_id",
      "ad_merchant_id",
      "ld_merchant_id",
    ],
  } = {}) {
    let selectedMerchantId = null;
    if (selectedAg) {
      selectedMerchantId = selectedAg;
    } else if (selectedLd) {
      selectedMerchantId = selectedLd;
    } else if (selectedAd) {
      selectedMerchantId = selectedAd;
    } else if (selectedRd) {
      selectedMerchantId = selectedRd;
    }

    if (!selectedMerchantId) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Selection",
        text: "Please select a valid merchant before downloading the Excel report.",
      });
      return;
    }

    const filteredValues = {
      ...filterValues,
      merchant_id: selectedMerchantId,
    };

    excludedKeys.forEach((key) => {
      delete filteredValues[key];
    });

    try {
      return await new Promise((resolve, reject) => {
        fetchLedgerReport({
          filterValues: filteredValues,
          resolve,
          reject,
        });
      });
    } catch (error) {
      console.error("Error while fetching Excel report:", error);
      throw error;
    }
  }

  const fetchLedgerReport = ({
    filterValues = {},
    append = false,
    initial = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }

    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        if (key === "transaction_date") {
          const date = filterValues[key];
          if (Array.isArray(date)) {
            const fromDate = date[0];
            const toDate = date[1] || fromDate;
            filteredObject["transaction_date"] = `${fromDate} to ${toDate}`;
          } else {
            filteredObject["transaction_date"] = `${date} to ${date}`;
          }
        } else {
          filteredObject[key] = filterValues[key];
        }
      }
    }

    setFilters(filteredObject);

    let selectedMerchantId = null;
    if (selectedAg) {
      selectedMerchantId = selectedAg;
    } else if (selectedLd) {
      selectedMerchantId = selectedLd;
    } else if (selectedAd) {
      selectedMerchantId = selectedAd;
    } else if (selectedRd) {
      selectedMerchantId = selectedRd;
    }

    const params = {
      ...filteredObject,
      ...pagination,
      ...(selectedMerchantId ? { merchant_id: selectedMerchantId } : {}),
      ...(resolve
        ? {
            include_excel: true,
            offset: 0,
            limit: pagination?.offset + pagination?.limit,
          }
        : {}),
    };

    API({
      ...walletRechgReport,
      params: initial ? { limit: 1 } : params,
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          console.log("test");
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              setFilteredTable(res.data.data.table_data);
              setTotalRows(res.data.data.total_rows);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        if (reject) {
          reject(error);
        }
      },
    });
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchLedgerReport({ append: true, filterValues: filters });
    }
  }, [pagination]);

  // merchant dropdown
  useEffect(() => {
    API({
      ...merchantDropdownReport,
      onSuccess: (res) => {
        setDropdown(res?.data?.data);
      },
      onError: (error) => {
        console.error("Error fetching merchant data: ", error);
      },
    });
  }, []);

  useEffect(() => {
    const idToUse = selectedLd
      ? selectedLd
      : selectedAd
      ? selectedAd
      : selectedRd
      ? selectedRd
      : selectedBrandId;

    if (idToUse) {
      const params = selectedLd
        ? { ld: selectedLd }
        : selectedAd
        ? { ad: selectedAd }
        : selectedRd
        ? { rd: selectedRd }
        : selectedBrandId
        ? { brand_id: selectedBrandId }
        : selectedAg
        ? { ag: selectedAg }
        : {};

      API({
        ...selectedDropdown,
        params: params,
        onSuccess: (res) => {
          const rdMerchants =
            res?.data?.data?.map((merchant) => ({
              value: merchant.merchant_id,
              label: merchant.name,
            })) || [];
          setRdOptions(rdMerchants);

          if (selectedRd) {
            const adMerchants =
              res?.data?.data?.map((merchant) => ({
                value: merchant.merchant_id,
                label: merchant.name,
              })) || [];
            setAdOptions(adMerchants);
          }
          if (selectedAd) {
            const ldMerchants =
              res?.data?.data?.map((merchant) => ({
                value: merchant.merchant_id,
                label: merchant.name,
              })) || [];
            setLdOptions(ldMerchants);
          }
          if (selectedLd) {
            const agMerchants =
              res?.data?.data?.map((merchant) => ({
                value: merchant.merchant_id,
                label: merchant.name,
              })) || [];
            setAgOptions(agMerchants);
          }
        },
        onError: (error) => {
          console.error("Error fetching selected dropdown data: ", error);
        },
      });
    } else {
      setRdOptions([]);
      setAdOptions([]);
      setLdOptions([]);
      setAgOptions([]);
    }
  }, [selectedBrandId, selectedRd, selectedAd, selectedLd]);

  const brandOptions =
    dropdown?.brand?.map((item) => ({
      value: item.brand_id,
      label: item.business_name,
    })) || [];

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Wallet Recharge Report
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchLedgerReport}
          forPage={"Wallet Recharge Report"}
          excelFunc={fetchApplicationExcel}
          loading={loading}
          setSelectedAd={setSelectedAd}
          setSelectedRd={setSelectedRd}
          setSelectedLd={setSelectedLd}
          setSelectedAg={setSelectedAg}
          setSelectedBrandId={setSelectedBrandId}
          excelFileName={"Wallet Recharge Report"}
          filters={[
            {
              placeholder: "Brand",
              id: "brand_id",
              filterType: "select",
              inputType: null,
              options: brandOptions.length
                ? brandOptions
                : [{ value: "", label: "No brands available" }],
              onChange: (selectedOption) => {
                setSelectedBrandId(selectedOption);
                setSelectedRd(null);
              },
            },
            {
              placeholder: "Transaction Date",
              id: "transaction_date",
              filterType: "date",
            },
          ]}
          extraFilters={[
            {
              placeholder: "Super Distributor",
              id: "rd_merchant_id",
              filterType: "select",
              inputType: null,
              options: rdOptions.length
                ? rdOptions
                : [{ value: "", label: "No merchants available" }],
              onChange: (selectedOption) => {
                setSelectedRd(selectedOption);
              },
            },
            {
              placeholder: "Master Distributor",
              id: "ad_merchant_id",
              filterType: "select",
              inputType: null,
              options: adOptions.length
                ? adOptions
                : [{ value: "", label: "No merchants available" }],
              onChange: (selectedOption) => {
                setSelectedAd(selectedOption);
              },
            },
            {
              placeholder: "Distributor",
              id: "ld_merchant_id",
              filterType: "select",
              inputType: null,
              options: ldOptions.length
                ? ldOptions
                : [{ value: "", label: "No merchants available" }],
              onChange: (selectedOption) => {
                setSelectedLd(selectedOption);
              },
            },
            {
              placeholder: "Retailer",
              id: "merchant_id",
              filterType: "select",
              inputType: null,
              options: agOptions.length
                ? agOptions
                : [{ value: "", label: "No merchants available" }],
              onChange: (selectedOption) => {
                setSelectedAg(selectedOption);
              },
            },
          ]}
          excludedKeys={[
            "brand_id",
            "rd_merchant_id",
            "ad_merchant_id",
            "ld_merchant_id",
          ]}
        ></Filter2>
        <PaginationTable
          columns={[
            { Header: "Transaction ID", accessor: "transaction_id" },
            { Header: "Merchant ID", accessor: "merchant_id" },
            { Header: "RRN", accessor: "rrn" },
            {
              Header: "Transaction UTR Details",
              accessor: "transaction_utr_details",
            },
            { Header: "Transaction Date", accessor: "transaction_date" },
            { Header: "Transaction Time", accessor: "transaction_time" },
            { Header: "Deposited Amount", accessor: "deposited_amount" },
            {
              Header: "Bank Name",
              accessor: "bank_name",
            },
            {
              Header: "Mode of Recharge",
              accessor: "mode_of_recharge",
            },
            { Header: "Customer VPA", accessor: "customer_vpa" },

            { Header: "Payment Gateway ID", accessor: "payment_gateway_id" },
            // { Header: "AP Transaction ID", accessor: "ap_txn_id" },
            // { Header: "GST", accessor: "gst" },
            // { Header: "Card Issuer", accessor: "card_issuer" },
            // { Header: "Card Number Field", accessor: "card_no_field" },
            // { Header: "Card Unique Code", accessor: "card_unique_code" },
            // { Header: "Card Bank Name", accessor: "card_bank_name" },
            // { Header: "Card Country", accessor: "card_country" },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
          totalRows={totalRows}
        />
      </div>
    </>
  );
};

export default WalletRechargeReport;
