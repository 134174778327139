import React, { useEffect, useState } from "react";
import { available } from "../urls";
import API from "../components/api2";
import { actionIcon, circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import Filter2 from "../components/Filter2";

const ManageRoles = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 14 });
  const [dropdown, setDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRoles({ initial: true });
    fetchRolesDropdown();
  }, []);

  const fetchRolesDropdown = () => {
    API({
      ...available,
      onSuccess: (res) => {
        setDropdown(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching roles dropdown: ", error);
      },
    });
  };

  const fetchRoles = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }

    API({
      ...available,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              if (res.data.data.role_id) {
                setFilteredTable([res.data.data]);
              } else {
                setFilteredTable(res.data.data.table_data);
                setTotal(res.data.data.total_rows);
              }
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error("Error fetching roles: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const changePageFunc = ({ role, targetPage }) => {
    if (role) {
      dispatch(
        changePage({
          page: "roleInformation",
          role_id: role.role_id,
        })
      );

      navigate("/", {
        state: {
          role,
        },
      });
    } else {
      dispatch(
        changePage({
          page: targetPage,
        })
      );
      navigate("/", {});
    }
  };
  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchRoles({ append: true });
    }
  }, [pagination]);

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            List of Roles
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchRoles}
          showExcelIcon={false}
          loading={loading}
          filters={[
            {
              placeholder: "Role ID",
              id: "role_id",
              filterType: "input",
              inputType: "number",
              options: [],
            },
            // {
            //   placeholder: "Role Name",
            //   id: "role_name",
            //   filterType: "select",
            //   inputType: null,
            //   options: dropdown?.map((item) => ({
            //     value: item.role_name,
            //     label: item.role_name,
            //   })),
            // },
            {
              placeholder: "Created On",
              id: "created_on",
              filterType: "input",
              inputType: "date",
              options: [],
            },
          ]}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              changePageFunc({ targetPage: "addRole" });
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD ROLE"}
            </p>
          </div>
        </Filter2>
        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() => changePageFunc({ role: row.original })}
                  />
                );
              },
            },
            { Header: "Role ID", accessor: "role_id" },
            { Header: "Role Name", accessor: "role_name" },
            {
              Header: "Created On",
              accessor: "created_on",
              Cell: ({ value }) => {
                const dateObject = new Date(value);
                const formattedDate = dateObject.toISOString().split("T")[0];
                return formattedDate;
              },
            },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
          totalRows={total}
        />
      </div>
    </>
  );
};

export default ManageRoles;
