import React, { useEffect, useRef, useState } from "react";
import {
  logout,
  menuShortcut,
  getShortcutServices,
  deleteShortcutServices,
  getProfile,
} from "../urls";
import {
  shortcut,
  wallet,
  notification,
  profileIcon,
  dropdownDots,
  signOut,
  rechgWallet,
  changePass,
  withdraw,
  viewProfile,
  aepsIcon,
  dmtIcon,
  bbpsIcon,
  travelIcon,
  financialIcon2,
  govtServicesIcon,
  bcsIcon,
  rechargeIcon,
  microAtmIcon,
  cmsIcon,
  devicesIcon,
  noImage2,
} from "../images/index";
import API from "./api2";
import { onLogout } from "../redux/slice/userSlice";
import { connect, useDispatch } from "react-redux";
import { getWallet } from "../urls";
import { useNavigate } from "react-router-dom";
import NotificationList from "./NotificationList";
import LangSelector from "./LanguageSelector";
import i18next from "i18next";
import { changePage } from "../redux/slice/dashboardSlice";
import Swal from "sweetalert2";
import { RiLockPasswordLine } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { BsBank } from "react-icons/bs";
import loaderGif from "../gif/buffer.gif";
import { IoMdNotificationsOutline } from "react-icons/io";

const Header = ({
  name,
  menus,
  isRegEnabled,
  style = {
    backgroundColor: "#194280",
    borderRadius: "10px",
    color: "#194280",
    position: "sticky",
    top: "0",
  },
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [totalBal, setTotalBal] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [showShortcut, setShowShortcut] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [availableShortcuts, setAvailableShorcuts] = useState([]);
  const [empType, setEmpType] = useState([]);
  const [desig, setDesig] = useState([]);
  const [profilePic, setProfilePic] = useState([]);
  const [username, setUsername] = useState([]);
  const [loading, setLoading] = useState(false);

  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchWallet();
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // fetch wallet
  const fetchWallet = () => {
    API({
      ...getWallet,
      onSuccess: (res) => {
        const closingBalance = parseFloat(res.data.data.closing_balance) || 0;
        const aepsClosingBalance =
          parseFloat(res.data.data.aeps_closing_balance) || 0;
        const totalBalance = closingBalance + aepsClosingBalance;

        setTotalBal(totalBalance.toFixed(2));
      },
      onError: (error) => {
        console.error("Error fetching balance: ", error);
      },
    });
  };

  // fetch employee
  useEffect(() => {
    API({
      ...getProfile,
      onSuccess: (res) => {
        setEmpType(res.data.data.employee_type);
        setDesig(res.data.data.designation);
        setProfilePic(res?.data?.data?.photo);
        setUsername(res.data.data.username);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, []);

  // rechg wallet
  const handleRechgClick = () => {
    if (empType === "MERCHANT") {
      dispatch(
        changePage({
          page: "rechargeMerchant",
        })
      );
    } else {
      Swal.fire({
        title: "Access Denied",
        text: "You are not authorized to perform this action.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  // aeps wallet transfer
  const handleAepsTransfer = () => {
    if (empType === "MERCHANT") {
      dispatch(
        changePage({
          page: "aepsWalletTransfer",
        })
      );
      navigate("/");
    } else {
      Swal.fire({
        title: "Access Denied",
        text: "You are not authorized to perform this action.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  // logout
  const handleLogout = () => {
    setLoading(true);
    API({
      ...logout,
      onSuccess: (res) => {
        setLoading(false);
        setIsDropdownOpen(!isDropdownOpen);
        dispatch(onLogout());
      },
      onError: (error) => {
        setLoading(false);
        console.error("Logout failed");
      },
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setShowNotification(false);
  };

  const toggleNotification = () => {
    setShowNotification(!showNotification);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
    i18next.changeLanguage(lang);
  }, []);

  const setLang = (data) => {
    localStorage.setItem("language", data);
    window.location.reload();
  };

  // get shortcut services
  const services = [
    {
      name: "Bank Correspondence Services",
      id: "bankCorrespondenceService",
      img: bcsIcon,
    },
    {
      name: "Aadhar Enabled Payment Services (AEPS)",
      id: "aadharEnablePayment",
      img: aepsIcon,
    },
    { name: "Micro Atm", id: "microATM", img: microAtmIcon },
    {
      name: "Domestic Money Transfer",
      id: "domesticMoneyTransfer",
      img: dmtIcon,
    },
    { name: "Cash Management Services", id: "cashManagement", img: cmsIcon },
    {
      name: "Bharat Bill Payment Services",
      id: "bharatBillPayment",
      img: bbpsIcon,
    },
    { name: "Travel Services", id: "travelServices", img: travelIcon },
    {
      name: "Financial Services",
      id: "financialServices",
      img: financialIcon2,
    },
    {
      name: "Government Services",
      id: "governmentServices",
      img: govtServicesIcon,
    },
    { name: "Recharge Services", id: "rechargeServices", img: rechargeIcon },
    { name: "Device Sales", id: "devicesSales", img: devicesIcon },
  ];

  const subServices = {
    "Bank Correspondence Services": [
      { name: "Account Opening", id: "" },
      { name: "Saving", id: "" },
      { name: "Current", id: "" },
      { name: "Recurring Deposit ", id: "" },
      { name: "Term Deposit", id: "" },
      { name: "Aadhaar Seeding", id: "" },
      { name: "Mobile Seeding", id: "" },
    ],
    "Aadhar Enabled Payment Services (AEPS)": [
      { name: "Withdrawal", id: "aepsWithdrawal" },
      { name: "Mini Statement", id: "aepsStatement" },
      { name: "Balance Enquiry", id: "aepsBalance" },
      { name: "Aadhaar Pay", id: "aepsAadharPay" },
    ],
    "Micro Atm": [
      { name: "Withdrawal", id: "microWithdawal" },
      { name: "Balance Enquiry", id: "microBalEnq" },
    ],
    "Domestic Money Transfer": [{ name: "Bank Transfer", id: "queryRemitter" }],
    "Cash Management Services": [{ name: "Pay Loan EMI", id: "" }],

    "Bharat Bill Payment Services": [
      {
        name: "Postpaid Mobile Bill",
        id: "bbpsMobPostpaidRecharge",
      },
      { name: "Gas Bill", id: "bbpsGasBill" },
      { name: "Water Bill", id: "bbpsWaterBill" },
      { name: "DTH Bill", id: "bbpsDthBill" },
      { name: "Electricity Bill", id: "bbpsElectBill" },
      { name: "Piped Gas", id: "bbpsPipedGas" },
      { name: "Insurance", id: "bbpsInsurance" },
      { name: "Loan Repayments", id: "bbpsLoanRep" },
      { name: "FastTag", id: "bbpsFastag" },
      { name: "Cable TV", id: "bbpsCableTv" },
      { name: "Education Fees", id: "bbpsEduFees" },
      { name: "Housing Society", id: "bbpsHousing" },
      { name: "Municipal Taxes", id: "bbpsMunTaxes" },
      { name: "Subscription Fees", id: "bbpsSubFees" },
      { name: "NPS", id: "bbpsNps" },
      { name: "Credit Cards", id: "bbpsCreditCards" },
      {
        name: "Clubs & Associations",
        id: "bbpsClubAssoc",
      },
      {
        name: "Hospital & Pathology",

        id: "bbpsHospital",
      },
      { name: "NCMC", id: "bbpsNcmc" },
      { name: "Landline Postpaid", id: "bbpsLandline" },
      { name: "Recurring Deposit", id: "bbpsRd" },
      {
        name: "Broadband Postpaid",
        id: "bbpsBroadband",
      },
      { name: "Rent", id: "bbpsRent" },
    ],
    "Travel Services": [
      { name: "Hotel Booking", id: "" },
      { name: "Flight Ticket Booking", id: "" },
      { name: "Bus Ticket Booking", id: "" },
      { name: "Train Ticket Booking", id: "" },
    ],

    "Financial Services": [{ name: "Insurance Services", id: "" }],
    "Government Services": [
      { name: "Pan Card", id: "" },
      { name: "Government Scheme Application", id: "" },
    ],
    "Recharge Services": [
      {
        name: "Mobile Recharge",
        id: "mobileRecharge",
      },
      { name: "DTH Recharge", id: "dthRecharge" },
      { name: "Fastag Recharge", id: "" },
    ],
    "Device Sales": [
      { name: "Printer", id: "" },
      { name: "FP Device", id: "" },
      { name: "MATM", id: "" },
    ],
  };

  const availableMenuNames = menus.map((menu) => menu.menu_name.toLowerCase());

  const availableShortcutServices = services.filter((service) =>
    availableMenuNames.some((menuName) =>
      service.name.toLowerCase().includes(menuName)
    )
  );

  // to display shortcut images
  const matchedServices = availableShortcuts
    .map((shortcut) =>
      services.find((service) => service.name === shortcut.menu_name)
    )
    .filter(Boolean);

  // save shortcuts
  const handleServiceClick = (id) => {
    setSelectedServices((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((serviceId) => serviceId !== id)
        : [...prevSelected, id]
    );
  };

  const saveShortcuts = () => {
    if (availableShortcuts.length < 5) {
      const selectedServiceIds = availableShortcutServices
        .filter((service) => selectedServices.includes(service.id))
        .map(
          (service) =>
            menus.find((menu) =>
              service.name.toLowerCase().includes(menu.menu_name.toLowerCase())
            ).menu_id
        );

      API({
        ...menuShortcut,
        body: { menu_id: selectedServiceIds },
        onSuccess: () => {
          Swal.fire({
            title: "Shortcuts Saved Successfully",
          }).then(() => {
            window.location.reload();
          });
        },
        onError: (error) => {
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Cannot add more than 5 shortcuts",
      });
    }
  };

  // get shortcut services after adding it
  useEffect(() => {
    API({
      ...getShortcutServices,
      onSuccess: (res) => {
        setAvailableShorcuts(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching shortcuts services: ", error);
      },
    });
  }, []);

  const changePageFunc = (pageName) => {
    dispatch(
      changePage({
        page: pageName,
      })
    );

    navigate("/");
  };

  // delete shortcuts
  const deleteShortcuts = (menu_id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this shortcut?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((confirmation) => {
      if (confirmation.isConfirmed) {
        API({
          ...deleteShortcutServices,
          body: { menu_id: menu_id },
          onSuccess: () => {
            Swal.fire({
              title: "Shortcut Deleted Successfully",
            }).then(() => {
              window.location.reload();
            });
          },
          onError: (error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          },
        });
      }
    });
  };
  const clickServiceShortcut = (menuName) => {
    const service = subServices[menuName];
    if (service && service.length > 0) {
      const firstSubServiceId = service[0].id;
      const selectedService = { name: menuName, id: firstSubServiceId };

      if (
        menuName === "Aadhar Enabled Payment Services (AEPS)" &&
        isRegEnabled === false
      ) {
        dispatch(
          changePage({
            page: "onBoarding",
          })
        );

        navigate("/");
      } else if (menuName === "Domestic Money Transfer") {
        dispatch(
          changePage({
            page: "queryRemitter",
          })
        );

        navigate("/");
      } else {
        dispatch(
          changePage({
            page: "serviceTabs",
            service: selectedService,
          })
        );

        navigate("/", {
          state: { service: selectedService, id: firstSubServiceId },
        });
      }
    }
  };

  return (
    <div className="py-2 mt-1 position-relative" style={style}>
      <div className="d-flex align-items-center justify-content-between px-3">
        <div className="d-flex align-items-center">
          <div className="position-relative">
            <img
              onClick={() => setShowShortcut(!showShortcut)}
              src={shortcut}
              alt="shortcut-icon"
              style={{ cursor: "pointer", pointerEvents: "all" }}
            />
            {showShortcut && (
              <div
                className="position-absolute px-4"
                style={{
                  backgroundColor: "#194280",
                  width: "900px",
                  zIndex: "999",
                  border: "1px solid white",
                  borderRadius: "10px",
                  left: "-40%",
                  top: "185%",
                  height: "auto",
                  transition: "all 0.3s ease-in-out",
                  overflow: "hidden auto",
                }}
              >
                <div className="row my-2">
                  {availableShortcutServices.length === 0 ||
                  empType !== "MERCHANT" ? (
                    <p
                      style={{ fontSize: "20px" }}
                      className="mb-0 text-white d-flex justify-content-center align-items-center"
                    >
                      No utility services available.
                    </p>
                  ) : (
                    availableShortcutServices.map((menuItem, index) => (
                      <div className="col-4 py-2 mt-2" key={index}>
                        <p
                          className="mb-0 p-2 text-center"
                          style={{
                            backgroundColor: selectedServices.includes(
                              menuItem.id
                            )
                              ? "white"
                              : "transparent",
                            fontSize: "14px",
                            fontWeight: "400",
                            cursor: "pointer",
                            color: selectedServices.includes(menuItem.id)
                              ? "#194280"
                              : "white",
                            border: "1px solid white",
                            borderRadius: "20px",
                          }}
                          onClick={() => handleServiceClick(menuItem.id)}
                        >
                          {menuItem.name}
                        </p>
                      </div>
                    ))
                  )}
                </div>

                {availableShortcutServices.length > 0 &&
                  empType === "MERCHANT" && (
                    <div className="text-end mx-3 my-4">
                      <button
                        className="border-0 px-3 py-2"
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          fontFamily: "Montserrat",
                          color: "rgba(25, 66, 128, 1)",
                          textDecoration: "underline",
                          cursor:
                            selectedServices.length === 0
                              ? "not-allowed"
                              : "pointer",
                          backgroundColor: "white",
                          borderRadius: "20px",
                          opacity: selectedServices.length === 0 ? "0.5" : "1",
                        }}
                        onClick={saveShortcuts}
                        disabled={selectedServices.length === 0}
                      >
                        Choose favourite
                      </button>
                    </div>
                  )}
              </div>
            )}
          </div>
          {/* display shortcuts  */}
          <div className="ms-3">
            <div className="d-flex align-items-center">
              {matchedServices.map((service, index) => (
                <div key={index}>
                  <img
                    className="ms-3"
                    style={{
                      cursor: "pointer",
                      width: "40px",
                      height: "40px",
                      pointerEvents: "all",
                    }}
                    src={service.img}
                    onClick={() => {
                      clickServiceShortcut(service.name);
                    }}
                    alt={service.name}
                  />
                  {availableShortcuts.some(
                    (shortcut) => shortcut.menu_name === service.name
                  ) &&
                    availableShortcuts.map(
                      (shortcut, shortcutIndex) =>
                        shortcut.menu_name === service.name && (
                          <span
                            className="ms-2"
                            key={shortcutIndex}
                            style={{
                              cursor: "pointer",
                              color: "white",
                              fontSize: "18px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteShortcuts(shortcut.menu);
                            }}
                          >
                            &times;
                          </span>
                        )
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="d-flex align-items-center position-relative"
          ref={dropdownRef}
        >
          <div className="me-4">
            <img style={{ cursor: "pointer" }} src={wallet} alt="wallet-icon" />
            <p
              style={{
                backgroundColor: "#148C43",
                borderRadius: "14px",
                fontSize: "14px",
                bottom: "-8px",
                left: "-30px",
                width: "100px",
              }}
              className="mb-0 text-white position-absolute px-2 pt-1 text-center"
            >
              ₹ {totalBal != "" ? totalBal : "loading..."}
            </p>
          </div>
          {/* <img
            style={{ cursor: "pointer", pointerEvents: "all" }}
            className="ms-4"
            src={notification}
            alt="notification-icon"
            onClick={toggleNotification}
          />
          {showNotification && (
            <NotificationList
              showNotification={showNotification}
              setShowNotification={setShowNotification}
            />
          )} */}
          <LangSelector setLang={setLang} />

          <div
            className="py-1 ps-2 pe-3 ms-4"
            style={{
              border: "1px solid rgba(147, 147, 147, 1)",
              borderRadius: "15px",
            }}
          >
            <div className="d-flex align-items-center">
              <img
                style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                src={profilePic != "" ? profilePic : noImage2}
                alt="profile-icon"
              />
              <div
                className="ms-3"
                style={{
                  fontFamily: "League Spartan",
                  color: "rgba(223, 227, 228, 1)",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                <p className="mb-0">
                  {username != "" ? username : "loading..."}&nbsp;
                  {name.split(" ")[0]}
                </p>
                <p
                  style={{
                    fontSize: "14px",
                  }}
                  className="mb-0"
                >
                  {desig != "" ? desig : "loading..."}
                </p>
              </div>
            </div>
          </div>
          <img
            style={{ cursor: "pointer", pointerEvents: "all" }}
            className="ms-3 "
            src={dropdownDots}
            alt="dots"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div
              className="p-3 mt-2"
              style={{
                position: "absolute",
                top: "100%",
                right: "20px",
                zIndex: 1050,
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "8px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                color: "rgba(25, 66, 128, 1)",
                fontWeight: "500",
              }}
            >
              <div
                style={{ cursor: "pointer", height: "38px" }}
                className="d-flex align-items-center dropdown_hvr"
              >
                <img
                  style={{ width: "22px" }}
                  src={viewProfile}
                  alt="view-profile"
                />
                <p
                  onClick={() => changePageFunc("profileSettings")}
                  className="mb-0 ms-3"
                >
                  Profile Setting
                </p>
              </div>
              <div
                onClick={handleRechgClick}
                style={{ cursor: "pointer", height: "38px" }}
                className="d-flex align-items-center mt-1 dropdown_hvr"
              >
                <img
                  style={{ width: "22px" }}
                  src={rechgWallet}
                  alt="recharge-wallet"
                />
                <p className="mb-0 ms-3">Recharge main wallet</p>
              </div>
              <div
                onClick={() => changePageFunc("commissionStructure")}
                style={{ cursor: "pointer", height: "38px" }}
                className="d-flex align-items-center mt-1 dropdown_hvr"
              >
                <CiPercent style={{ fontSize: "22px" }} />
                <p className="mb-0 ms-3">Commission Structure</p>
              </div>
              <div
                onClick={handleAepsTransfer}
                style={{ cursor: "pointer", height: "38px" }}
                className="d-flex align-items-center mt-2 dropdown_hvr"
              >
                <img
                  style={{ width: "22px" }}
                  src={withdraw}
                  alt="withdraw-money"
                />
                <p className="mb-0 ms-3">AEPS Fund Settlement</p>
              </div>
              <div
                style={{ cursor: "pointer", height: "38px" }}
                className="d-flex align-items-center mt-2 dropdown_hvr"
              >
                <img
                  style={{ width: "22px" }}
                  src={changePass}
                  alt="change-password"
                />
                <p
                  onClick={() => changePageFunc("changePassProfile")}
                  className="mb-0 ms-3"
                >
                  Change Password
                </p>
              </div>
              <div
                onClick={() => changePageFunc("manageUpin")}
                style={{ cursor: "pointer", height: "38px" }}
                className="d-flex align-items-center mt-2 dropdown_hvr"
              >
                <RiLockPasswordLine style={{ fontSize: "22px" }} />
                <p className="mb-0 ms-3">Manage UPIN</p>
              </div>
              <div
                onClick={() => changePageFunc("allAccounts")}
                style={{ cursor: "pointer", height: "38px" }}
                className="d-flex align-items-center mt-2 dropdown_hvr"
              >
                <BsBank style={{ fontSize: "22px" }} />
                <p className="mb-0 ms-3">Manage Bank Accounts</p>
              </div>
              <div
                onClick={() => changePageFunc("addAlert")}
                style={{ cursor: "pointer", height: "38px" }}
                className="d-flex align-items-center mt-2 dropdown_hvr"
              >
                <IoMdNotificationsOutline style={{ fontSize: "22px" }} />
                <p className="mb-0 ms-3">Add Alerts</p>
              </div>
              <div
                onClick={handleLogout}
                style={{ cursor: "pointer", height: "38px" }}
                className="d-flex align-items-center mt-2 dropdown_hvr"
              >
                <img style={{ width: "22px" }} src={signOut} alt="sign-out" />
                {loading ? (
                  <img
                    src={loaderGif}
                    alt="Loading..."
                    style={{
                      height: "30px",
                      marginLeft: "10px",
                    }}
                  />
                ) : (
                  <p className="mb-0 ms-3">Sign out</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  name: state.user.name,
  menus: state.user.menus,
  isRegEnabled: state.user.aepsReg,
});

export default connect(mapStateToProps)(Header);
