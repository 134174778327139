import React, { useEffect, useState } from "react";
import { brandDropdown, getBusinessBrand, merchTransaction } from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { actionIcon, circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Filter2 from "../components/Filter2";

const MerchantTransactions = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 14 });
  const [dropdown, setDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const [inActive, setInActive] = useState();

  useEffect(() => {
    fetchBrandsDropdown();
  }, []);

  async function fetchBrandsExcel({ filterValues = {} } = {}) {
    return await new Promise((resolve, reject) => {
      fetchBrands({
        filterValues: filterValues,
        resolve: resolve,
        reject: reject,
      });
    });
  }

  const fetchBrandsDropdown = () => {
    API({
      ...brandDropdown,
      onSuccess: (res) => {
        setDropdown(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching brands dropdown: ", error);
      },
    });
  };

  const fetchBrands = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        if (key === "created_on") {
          const date = filterValues[key];
          if (Array.isArray(date)) {
            const fromDate = date[0];
            const toDate = date[1] || fromDate;
            filteredObject["created_on"] = `${fromDate} to ${toDate}`;
          } else {
            filteredObject["created_on"] = `${date} to ${date}`;
          }
        } else {
          filteredObject[key] = filterValues[key];
        }
      }
    }
    API({
      ...merchTransaction,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          setActive(res.data.data.active_count);
          setInActive(res.data.data.inactive_count);
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              setFilteredTable(res.data.data.table_data);
              setTotalRows(res.data.data.total_rows);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error("Error fetching brands: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const changePageFunc = ({ brand_id, targetPage } = {}) => {
    if (brand_id) {
      dispatch(
        changePage({
          page: "brandInformation",
          brand_id: brand_id,
        })
      );

      navigate("/", {
        state: {
          brand_id,
        },
      });
    } else {
      dispatch(
        changePage({
          page: targetPage,
        })
      );
      navigate("/", {});
    }
  };
  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchBrands({ append: true });
    }
  }, [pagination]);

  const now = new Date();
  const dateString = now.toISOString().split("T")[0];
  const fileName = `Merchant Transaction Report (${dateString})`;

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Merchant Transactions
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchBrands}
          loading={loading}
          excelFunc={fetchBrandsExcel}
          excelFileName={fileName}
          filters={[
            {
              placeholder: "Merchant ID",
              id: "merchant_id",
              filterType: "input",
              inputType: "text",
              options: [],
            },
            {
              placeholder: "Date",
              id: "created_on",
              filterType: "date",
            },
          ]}
        ></Filter2>
        <PaginationTable
          columns={[
            { Header: "Transaction ID", accessor: "transaction_id" },
            { Header: "Brand ID", accessor: "brand_id" },
            { Header: "Merchant ID", accessor: "merchant_id" },
            { Header: "Request ID", accessor: "request_id" },
            { Header: "Reference Number", accessor: "reference_num" },
            { Header: "Debit Amount", accessor: "debit_amount" },
            { Header: "Credit Amount", accessor: "credit_amount" },
            { Header: "Transaction Date", accessor: "transaction_date" },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
          totalRows={totalRows}
        />
      </div>
    </>
  );
};

export default MerchantTransactions;
