import React, { useState } from "react";
import Select from "react-select";
import { apply, filter } from "../images";
import { ExportToExcel } from "./ExportToExcel";
import { GrPowerReset } from "react-icons/gr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { ThreeDots } from "react-loader-spinner";

// Date input component
const DateInputComponent = ({ onChange, id, value, singleDate }) => {
  const [startDate, setStartDate] = useState(value ? new Date(value[0]) : null);
  const [endDate, setEndDate] = useState(value ? new Date(value[1]) : null);

  const handleDateChange = (dates) => {
    if (singleDate) {
      const selectedDate = dates;
      setStartDate(selectedDate);
      const formattedDate = selectedDate
        ? format(selectedDate, "yyyy-MM-dd")
        : "";
      onChange(id, formattedDate);
    } else {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

      if (start && end) {
        const formattedStart = format(start, "yyyy-MM-dd");
        const formattedEnd = format(end, "yyyy-MM-dd");
        onChange(id, [formattedStart, formattedEnd]);
      } else if (start) {
        const formattedStart = format(start, "yyyy-MM-dd");
        onChange(id, [formattedStart, formattedStart]);
      }
    }
  };

  return (
    <DatePicker
      showIcon
      selected={startDate}
      onChange={handleDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange={!singleDate}
      selectsStart={!singleDate}
      selectsEnd={!singleDate}
      isClearable
      minDate={id === "end_date" && startDate ? startDate : null}
      placeholderText={
        singleDate
          ? id === "start_date"
            ? "Select Start Date"
            : "Select End Date"
          : "Select date range"
      }
      dateFormat="yyyy-MM-dd"
      className="form-control"
      style={{
        fontSize: "15px",
        width: "100%",
        padding: "8px 51px",
        borderRadius: "10px",
      }}
    />
  );
};

const InputComponent = ({ onChange, id, placeholder, type, value }) => (
  <input
    type={type}
    id={id}
    placeholder={placeholder}
    style={{
      fontSize: "15px",
      width: "100%",
      padding: "8px 5px",
      borderRadius: "10px",
      backgroundColor: "rgba(255, 231, 159, 0.4)",
    }}
    onChange={(e) => onChange(id, e.target.value)}
    value={value || ""}
  />
);

const SelectComponent = ({ onChange, id, placeholder, options, value }) => (
  <Select
    options={options}
    placeholder={placeholder}
    value={value ? { value: value, label: value } : null}
    onChange={(selectedOption) => onChange(id, selectedOption.value)}
    menuPortalTarget={document.body}
    styles={{
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "rgba(255, 231, 159, 0.4)",
        borderRadius: "10px",
        width: "100%",
        border: "none",
        boxShadow: state.isFocused ? "none" : provided.boxShadow,
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "#000",
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
      }),
    }}
  />
);

const Filter2 = ({
  filters = [],
  forPage = "",
  getDataFunc,
  excelFunc,
  excelFileName,
  showExcelIcon = true,
  extraFilters = [],
  excludedKeys = [],
  setSelectedAd,
  setSelectedRd,
  setSelectedLd,
  setSelectedAg,
  setSelectedBrandId,
  loading,
  children = <></>,
}) => {
  const [filterValues, setFilterValues] = useState({});
  const [showExtraFilters, setShowExtraFilters] = useState(false);

  const handleInputChange = (id, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const resetFilters = () => {
    setFilterValues({});

    if (
      forPage === "Ledger Report" ||
      forPage === "Wallet Recharge Report" ||
      forPage === "Network Wallet Report"
    ) {
      setSelectedBrandId(null);
      setSelectedRd(null);
      setSelectedAd(null);
      setSelectedLd(null);
      setSelectedAg(null);
    }

    // getDataFunc({ filterValues: {} });
  };
  const applyFilters = () => {
    const filteredPayload = Object.fromEntries(
      Object.entries(filterValues).filter(
        ([key]) => !excludedKeys.includes(key)
      )
    );
    getDataFunc({ filterValues: filteredPayload });
  };

  const toggleExtraFilters = () => {
    setShowExtraFilters((prev) => !prev);
  };
  return (
    <>
      <div
        className="mx-3 mt-4"
        style={{
          borderTop: "1.5px solid #D9D9D9",
          borderBottom: "2px solid #063E70",
        }}
      >
        <div
          className="px-3 mt-1 py-2 mb-4 d-flex align-items-center justify-content-between"
          style={{ backgroundColor: "#D9D9D9", borderTop: "1px solid #D9D9D9" }}
        >
          <div className="d-flex align-items-center">
            <h3
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "24px",
                color: "rgba(25, 66, 128, 1)",
              }}
              className="mb-0"
            >
              Filters
            </h3>
            <img className="ms-3" src={filter} alt="filter-icon" />
          </div>
          {(forPage === "Ledger Report" ||
            forPage === "Transaction Report" ||
            forPage === "Wallet Recharge Report" ||
            forPage === "Network Wallet Report" ||
            forPage === "Commission Report") && (
            <div
              style={{
                cursor: "pointer",
                color: "#194280",
                zIndex: 1,
                transition: "transform 0.3s ease, opacity 0.3s ease",
                transform: showExtraFilters ? "rotate(180deg)" : "rotate(0deg)",
                opacity: showExtraFilters ? 1 : 0.8,
              }}
              onClick={toggleExtraFilters}
            >
              {showExtraFilters ? (
                <AiOutlineUp size={20} />
              ) : (
                <AiOutlineDown size={20} />
              )}
            </div>
          )}
        </div>
        <div className="row pb-3 px-3">
          {filters.map((filter, key) => {
            if (!filter || !filter.filterType) {
              return null;
            }
            const modOnChange = (id, value) => {
              handleInputChange(id, value);
              if (filter?.onChange) {
                filter?.onChange(value);
              }
            };
            return (
              <div className="col p-0 pe-2" key={key}>
                {filter.filterType === "select" ? (
                  <SelectComponent
                    value={
                      filterValues.hasOwnProperty(filter.id)
                        ? filterValues[filter.id]
                        : undefined
                    }
                    placeholder={filter.placeholder}
                    options={filter.options}
                    id={filter.id}
                    onChange={modOnChange}
                  />
                ) : filter.filterType === "input" ? (
                  <InputComponent
                    id={filter.id}
                    placeholder={filter.placeholder}
                    type={filter.inputType}
                    onChange={modOnChange}
                    value={
                      filterValues.hasOwnProperty(filter.id)
                        ? filterValues[filter.id]
                        : undefined
                    }
                  />
                ) : filter.filterType === "date" ? (
                  <DateInputComponent
                    id={filter.id}
                    onChange={handleInputChange}
                    value={
                      filterValues.hasOwnProperty(filter.id)
                        ? filterValues[filter.id]
                        : undefined
                    }
                    singleDate={filter.singleDate || false}
                  />
                ) : (
                  <></>
                )}
              </div>
            );
          })}
          <div className="col-1 p-0">
            {loading ? (
              <ThreeDots
                height="35"
                width="35"
                color="#194280"
                ariaLabel="loading"
              />
            ) : (
              <img
                onClick={applyFilters}
                style={{ cursor: "pointer", pointerEvents: "all" }}
                src={apply}
                alt="apply"
              />
            )}
          </div>
          <div className="col-1">
            <GrPowerReset
              style={{
                marginTop: "9px",
                marginLeft: "8px",
                fontSize: "18px",
                cursor: "pointer",
                color: "#194280",
              }}
              onClick={resetFilters}
            />
          </div>
        </div>
        {(forPage === "Ledger Report" ||
          forPage === "Transaction Report" ||
          forPage === "Wallet Recharge Report" ||
          forPage === "Network Wallet Report" ||
          forPage === "Commission Report") && (
          // Extra Filters Section
          <div
            className="px-3"
            style={{
              maxHeight: showExtraFilters ? "300px" : "0",
              opacity: showExtraFilters ? "1" : "0",
              overflow: "hidden",
              transition:
                "max-height 0.6s ease-in-out, opacity 0.6s ease-in-out",
              backgroundColor: "#f9f9f9",
              width: "100%",
            }}
          >
            <div className="row mb-3">
              {extraFilters.map((filter, key) => {
                if (!filter || !filter.filterType) {
                  return null;
                }
                const modOnChange = (id, value) => {
                  handleInputChange(id, value);
                  if (filter?.onChange) {
                    filter?.onChange(value);
                  }
                };
                return (
                  <div className="col-3 p-0 pe-2" key={key}>
                    {filter.filterType === "select" ? (
                      <SelectComponent
                        value={
                          filterValues.hasOwnProperty(filter.id)
                            ? filterValues[filter.id]
                            : undefined
                        }
                        placeholder={filter.placeholder}
                        options={filter.options}
                        id={filter.id}
                        onChange={modOnChange}
                      />
                    ) : filter.filterType === "input" ? (
                      <InputComponent
                        id={filter.id}
                        placeholder={filter.placeholder}
                        type={filter.inputType}
                        onChange={modOnChange}
                        value={
                          filterValues.hasOwnProperty(filter.id)
                            ? filterValues[filter.id]
                            : undefined
                        }
                      />
                    ) : filter.filterType === "date" ? (
                      <DateInputComponent
                        id={filter.id}
                        onChange={handleInputChange}
                        value={
                          filterValues.hasOwnProperty(filter.id)
                            ? filterValues[filter.id]
                            : undefined
                        }
                        singleDate={filter.singleDate || false}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div
        className={`d-flex align-items-center ${
          showExcelIcon ? "justify-content-between" : "justify-content-end"
        } mt-2 px-3`}
      >
        {showExcelIcon && (
          <ExportToExcel
            fetchOnClickFunc={() => excelFunc({ filterValues })}
            fileName={excelFileName}
          />
        )}
        {children}
      </div>
    </>
  );
};

export default Filter2;
