import React, { useEffect, useState } from "react";
import API from "../../components/api2";
import { getAssignEmp, postProfileRole } from "../../urls";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { circles } from "../../images";
import InputForm from "../../components/InputForm";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePage } from "../../redux/slice/dashboardSlice";
import loaderGif from "../../gif/buffer.gif";
import Select from "react-select";

const NewProfileRole = () => {
  const [profileName, setProfileName] = useState("");
  // const [profileCode, setProfileCode] = useState("");
  const [profileRole, setProfileRole] = useState("");
  const [dropdown, setDropdown] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "manageProfileRole",
      })
    );
    navigate("/");
  };

  useEffect(() => {
    API({
      ...getAssignEmp,
      onSuccess: (res) => {
        setDropdown(res.data.data.employee_code);
      },
      onError: (error) => {
        console.error("Error fetching profile role: ", error);
      },
    });
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);

    API({
      ...postProfileRole,
      body: {
        // profile_code: profileCode,
        profile_name: profileName,
        profile_role: profileRole,
      },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          changePageFunc();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }

  // const isFormIncomplete = !profileName || !profileCode || !profileRole;
  const isFormIncomplete = !profileName || !profileRole;

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-5 py-4 me-2 position-relative">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <form onSubmit={onSubmit} className="text-start">
          <h2
            className="pb-3 mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
            }}
          >
            Add Profile Role
          </h2>
          <div className="mt-4 col-6">
            {/* <label
              style={{
                marginTop: "4px",
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
              htmlFor="profile_code"
              className="form-label"
            >
              Profile Code
            </label>
            <Select
              id="profile_code"
              name="profile_code"
              placeholder="Select Profile Code"
              options={dropdown.map((code) => ({
                value: code,
                label: code,
              }))}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }),
              }}
              value={dropdown.find((option) => option.value === profileCode)}
              onChange={(selectedOption) =>
                setProfileCode(selectedOption.value)
              }
            /> */}
            <InputForm
              type="text"
              id="profile_name"
              name="profile_name"
              placeholder="Profile Name"
              value={profileName}
              onChange={(event) => setProfileName(event.target.value)}
            />
            <InputForm
              type="text"
              id="profile_role"
              name="profile_role"
              placeholder="Profile Role"
              value={profileRole}
              onChange={(event) => setProfileRole(event.target.value)}
            />
          </div>
          <div className="mt-4 d-flex justify-content-between col-6">
            <Button onClick={changePageFunc}>Back</Button>
            <Button
              type="submit"
              disabled={isFormIncomplete || loading}
              style={{ opacity: isFormIncomplete || loading ? 0.5 : 1 }}
            >
              {loading ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={loaderGif}
                  alt="Loading..."
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewProfileRole;
