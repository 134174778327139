import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { circles } from "../images";
import InputForm from "../components/InputForm";
import Button from "../components/Button";
import { connect, useDispatch } from "react-redux";
import { changePage, setBackPage } from "../redux/slice/dashboardSlice";
import { setAmount } from "../redux/slice/paymentSlice";
import { dmtBankList, getProfile, getWallet, manualWalletRechg } from "../urls";
import API from "../components/api2";
import Swal from "sweetalert2";
import Select from "react-select";

const RechargeMerchant = ({ name }) => {
  const [username, setUsername] = useState([]);
  const [rechargeAmount, setRechargeAmount] = useState("");
  const [mainBalance, setMainBalance] = useState(0);
  const [transferOption, setTransferOption] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [manualRechargeData, setManualRechargeData] = useState({
    deposit_date: "",
    transaction_amount: "",
    bank_name: "",
    payment_mode: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [bankList, setBankList] = useState([]);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value !== "" && Number(value) <= 99) {
      setErrorMessage("Amount must be greater than 99");
    } else {
      setErrorMessage("");
    }

    setManualRechargeData({
      ...manualRechargeData,
      transaction_amount: value,
    });
  };

  useEffect(() => {
    API({
      ...getProfile,
      onSuccess: (res) => {
        setUsername(res.data.data.username);
      },
      onError: (error) => {
        console.log(error);
      },
    });

    API({
      ...getWallet,
      onSuccess: (res) => {
        const mainClosingBalance =
          parseFloat(res.data.data.closing_balance) || 0;
        setMainBalance(mainClosingBalance);
      },
      onError: (error) => {
        console.error("Error fetching balance: ", error);
      },
    });
  }, []);

  const handleRechargeAmountChange = (event) => {
    const value = event.target.value;
    if (value > 0 || value === "") {
      setRechargeAmount(value);
    }
  };

  const handleRecharge = (event) => {
    dispatch(
      setBackPage({
        backPage: "rechargeMerchant",
      })
    );
    dispatch(
      changePage({
        page: "payment",
      })
    );
    dispatch(
      setAmount({
        amount: rechargeAmount,
      })
    );

    event.preventDefault();
    if (!rechargeAmount) {
      return;
    }

    setRechargeAmount("");
  };

  const handleTransferOptionChange = (event) => {
    setTransferOption(event.target.value);
  };

  const handleSelectOption = () => {
    if (
      transferOption === "onlineRecharge" ||
      transferOption === "manualRecharge"
    ) {
      setIsFormVisible(true);
    }
  };

  const handleManualRecharge = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure you want to proceed with the manual recharge?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Proceed",
      cancelButtonText: "No, Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Enter 6-digit UPIN",
          input: "password",
          inputAttributes: {
            maxlength: 6,
            autocapitalize: "off",
            autocorrect: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Submit",
          cancelButtonText: "Cancel",
          inputValidator: (value) => {
            if (!value) {
              return "UPIN is required!";
            } else if (!/^\d{6}$/.test(value)) {
              return "Please enter a valid 6-digit UPIN!";
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            const upin = result.value;

            const requestData = {
              deposit_date: manualRechargeData.deposit_date,
              transaction_amount: manualRechargeData.transaction_amount,
              upin: upin,
              payment_mode: manualRechargeData.payment_mode,
              bank_name: manualRechargeData.bank_name,
            };

            if (manualRechargeData.payment_mode === "online") {
              requestData.utr = manualRechargeData.utr;
            }

            const formData = new FormData();

            for (const key in requestData) {
              formData.append(key, requestData[key]);
            }

            if (
              manualRechargeData.payment_mode === "cash" &&
              manualRechargeData.attachment
            ) {
              formData.append("attachment", manualRechargeData.attachment);
            }

            setIsLoading(true);

            API({
              ...manualWalletRechg,
              body: {
                ...requestData,
                attachment: formData.get("attachment") || undefined,
              },
              isFormData: true,
              onSuccess: (res) => {
                setIsLoading(false);
                Swal.fire({
                  title: `${res.data.message}`,
                }).then(() => {
                  window.location.reload();
                });
              },
              onError: (error) => {
                setIsLoading(false);
                Swal.fire({
                  icon: "error",
                  title: `${error.response.data.message}`,
                });
              },
            });
          }
        });
      }
    });
  };

  // banklist
  useEffect(() => {
    API({
      ...dmtBankList,
      onSuccess: (res) => {
        const data = res.data.data;
        const bankOptions = data.map((bank) => ({
          value: bank.bank_name,
          label: bank.bank_name,
        }));
        setBankList(bankOptions);
      },
      onError: (error) => {
        console.log("Error", error);
      },
    });
  }, []);

  return (
    <>
      <Header />

      <section className="h-100">
        <div
          className="bg-worker my-2 flex-grow-1 w-100 overflow-y-scroll mx-auto px-3 pt-4 me-2 position-relative"
          style={{ height: "98%" }}
        >
          <img
            style={{ position: "absolute", right: "0", top: "0" }}
            src={circles}
            alt="circles"
          />
          <h2
            className="pb-4 mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
            }}
          >
            Recharge Main Wallet
          </h2>

          {!isFormVisible && (
            <section>
              <div className="mt-4 px-5">
                <h3
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                >
                  Main Wallet Information:
                </h3>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                  className="mb-0 mt-4"
                >
                  Main Wallet Balance:
                  <span
                    className="ms-2"
                    style={{ color: "#AD8352", fontFamily: "Montserrat" }}
                  >
                    Rs {mainBalance}
                  </span>
                </p>
              </div>
              <div className="mt-5 px-5">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                  className="mb-0 mt-4"
                >
                  Select the mode of recharge:
                </p>
                <div className="mt-4">
                  <label
                    style={{
                      color: "#696666",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    className="d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      value="onlineRecharge"
                      checked={transferOption === "onlineRecharge"}
                      onChange={handleTransferOptionChange}
                      className="me-3"
                    />
                    Online Recharge
                  </label>
                  <label
                    style={{
                      color: "#696666",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    className="d-flex align-items-center mt-3"
                  >
                    <input
                      type="radio"
                      value="manualRecharge"
                      checked={transferOption === "manualRecharge"}
                      onChange={handleTransferOptionChange}
                      className="me-3"
                    />
                    Manual Recharge
                  </label>
                  <div className="mt-4 ms-4">
                    <Button
                      onClick={handleSelectOption}
                      disabled={!transferOption}
                      style={{
                        opacity: transferOption ? 1 : 0.5,
                        cursor: transferOption ? "pointer" : "not-allowed",
                      }}
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* online recharge  */}
          {isFormVisible && transferOption === "onlineRecharge" && (
            <form onSubmit={handleRecharge}>
              <div className="mt-5 col-5 mx-auto">
                <InputForm
                  type={"number"}
                  id={"merchant_id"}
                  name="merchant_id"
                  placeholder={"Merchant ID"}
                  value={username}
                  disabled={true}
                />

                <InputForm
                  type={"text"}
                  id={"name"}
                  name="name"
                  placeholder={"Merchant Name"}
                  value={name}
                  disabled={true}
                />

                <InputForm
                  type={"number"}
                  id={"recharge"}
                  name="recharge"
                  placeholder={"Amount"}
                  value={rechargeAmount}
                  onChange={handleRechargeAmountChange}
                />
                <div className="mt-4 text-center d-flex flex-column col-6 mx-auto">
                  <Button
                    type="submit"
                    disabled={!rechargeAmount}
                    style={{
                      opacity: rechargeAmount ? 1 : 0.5,
                      cursor: rechargeAmount ? "pointer" : "not-allowed",
                    }}
                  >
                    Recharge
                  </Button>
                  <button
                    style={{
                      color: "#1A1918",
                      textDecoration: "underline",
                      backgroundColor: "white",
                    }}
                    className="mb-0 mt-1 border-0"
                    onClick={() => setIsFormVisible(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}

          {/* manual recharge */}
          {isFormVisible && transferOption === "manualRecharge" && (
            <form className="col-8 mx-auto mt-3">
              <div className="row">
                <div className="col-6">
                  <InputForm
                    type="date"
                    id="deposit_date"
                    name="deposit_date"
                    placeholder="Deposit Date"
                    value={manualRechargeData.deposit_date}
                    onChange={(e) =>
                      setManualRechargeData({
                        ...manualRechargeData,
                        deposit_date: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-6">
                  <InputForm
                    type="number"
                    id="deposit_amount"
                    name="deposit_amount"
                    placeholder="Deposit Amount (in Rs)"
                    value={manualRechargeData.transaction_amount}
                    onChange={handleInputChange}
                  />
                  {errorMessage && (
                    <div style={{ color: "red", position: "absolute" }}>
                      {errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-6 mt-3">
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      color: "#696666",
                      fontSize: "14px",
                    }}
                    htmlFor="bank_name"
                  >
                    Bank Name
                  </label>
                  <Select
                    id="bank_name"
                    name="bank_name"
                    value={
                      bankList.find(
                        (option) =>
                          option.value === manualRechargeData.bank_name
                      ) || null
                    }
                    options={bankList}
                    placeholder="Select Bank"
                    onChange={(option) =>
                      setManualRechargeData({
                        ...manualRechargeData,
                        bank_name: option?.value || "",
                      })
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#FFF5D4",
                        width: "100%",
                        borderRadius: "10px",
                        border: "0px",
                        fontSize: "14px",
                        boxShadow: "none",
                        "&:focus": {
                          outline: "none",
                          boxShadow: "none",
                        },
                      }),
                      menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition="absolute"
                    menuPlacement="auto"
                  />
                </div>
                <div className="col-12 mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <p
                      className="mb-0"
                      style={{
                        fontFamily: "Montserrat",
                        color: "#696666",
                        fontSize: "15px",
                      }}
                    >
                      Payment Mode:
                    </p>
                    <label
                      style={{
                        fontFamily: "Montserrat",
                        color: "#696666",
                        fontSize: "14px",
                      }}
                      className="d-flex align-items-center"
                    >
                      <input
                        type="radio"
                        value="online"
                        checked={manualRechargeData.payment_mode === "online"}
                        onChange={(e) =>
                          setManualRechargeData({
                            ...manualRechargeData,
                            payment_mode: e.target.value,
                          })
                        }
                        className="me-3"
                      />
                      UPI/NEFT/IMPS/RTGS
                    </label>
                    <label
                      style={{
                        fontFamily: "Montserrat",
                        color: "#696666",
                        fontSize: "14px",
                      }}
                      className="d-flex align-items-center"
                    >
                      <input
                        type="radio"
                        value="cash"
                        checked={manualRechargeData.payment_mode === "cash"}
                        onChange={(e) =>
                          setManualRechargeData({
                            ...manualRechargeData,
                            payment_mode: e.target.value,
                          })
                        }
                        className="me-3"
                      />
                      Bank Deposit/CDM/ATM
                    </label>
                  </div>
                </div>

                {/* Conditional Input for UTR Number if Online is selected */}
                {manualRechargeData.payment_mode === "online" && (
                  <div className="col-6 mt-2">
                    <InputForm
                      type="text"
                      id="utr"
                      name="utr"
                      placeholder="UTR Number"
                      value={manualRechargeData.utr}
                      onChange={(e) => {
                        const value = e.target.value;
                        const alphanumericRegex = /^[a-zA-Z0-9]{0,22}$/;

                        if (alphanumericRegex.test(value)) {
                          setManualRechargeData({
                            ...manualRechargeData,
                            utr: value,
                          });
                        }
                      }}
                    />
                  </div>
                )}

                {/* Conditional Upload Section if Cash is selected */}
                {manualRechargeData.payment_mode === "cash" && (
                  <div className="mt-3 col-6">
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        color: "#696666",
                        fontSize: "15px",
                      }}
                    >
                      Upload Supporting Documents
                    </p>
                    <input
                      type="file"
                      className="p-2"
                      id="attachment"
                      name="attachment"
                      style={{
                        width: "100%",
                        backgroundColor: "#FFF5D4",
                        fontSize: "13px",
                        fontFamily: "Montserrat",
                        borderRadius: "10px",
                        marginTop: "-8px",
                      }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setManualRechargeData({
                            ...manualRechargeData,
                            attachment: file,
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="mt-4 pt-1 text-center d-flex flex-column col-6 mx-auto">
                <Button
                  type="button"
                  disabled={
                    isLoading ||
                    !manualRechargeData.deposit_date ||
                    !manualRechargeData.transaction_amount ||
                    !manualRechargeData.bank_name ||
                    !manualRechargeData.payment_mode ||
                    (manualRechargeData.payment_mode === "online" &&
                      !manualRechargeData.utr) ||
                    (manualRechargeData.payment_mode === "cash" &&
                      !manualRechargeData.attachment) ||
                    Number(manualRechargeData.transaction_amount) <= 99
                  }
                  style={{
                    opacity:
                      isLoading ||
                      !manualRechargeData.deposit_date ||
                      !manualRechargeData.transaction_amount ||
                      !manualRechargeData.bank_name ||
                      !manualRechargeData.payment_mode ||
                      (manualRechargeData.payment_mode === "online" &&
                        !manualRechargeData.utr) ||
                      (manualRechargeData.payment_mode === "cash" &&
                        !manualRechargeData.attachment) ||
                      Number(manualRechargeData.transaction_amount) <= 99
                        ? 0.5
                        : 1,
                    cursor:
                      isLoading ||
                      !manualRechargeData.deposit_date ||
                      !manualRechargeData.transaction_amount ||
                      !manualRechargeData.bank_name ||
                      !manualRechargeData.payment_mode ||
                      (manualRechargeData.payment_mode === "online" &&
                        !manualRechargeData.utr) ||
                      (manualRechargeData.payment_mode === "cash" &&
                        !manualRechargeData.attachment) ||
                      Number(manualRechargeData.transaction_amount) <= 99
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={handleManualRecharge}
                >
                  {isLoading ? "Loading..." : "Save"}
                </Button>
                <button
                  style={{
                    color: "#1A1918",
                    textDecoration: "underline",
                    backgroundColor: "white",
                  }}
                  className="mb-0 mt-1 border-0"
                  onClick={() => setIsFormVisible(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          )}
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  name: state.user.name,
});
export default connect(mapStateToProps)(RechargeMerchant);
