import React, { useState, useEffect } from "react";
import Select from "react-select";
import API from "../../components/api2";
import { newAlert, getAlerts, updateAlertStatus } from "../../urls";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { circles } from "../../images";
import InputForm from "../../components/InputForm";
import Swal from "sweetalert2";
import { changePage } from "../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddAlert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const alertOptions = [
    { value: "Home Screen", label: "Home Screen" },
    { value: "Login Screen", label: "Login Screen" },
  ];

  const handleAlertMessageChange = (event) => {
    const value = event.target.value;
    setAlertMessage(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    API({
      ...newAlert,
      body: { alert_message: alertMessage, alert_type: alertType.value },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          setAlertType(null);
          setAlertMessage("");
          fetchAlerts();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response?.data?.message || "Something went wrong!"}`,
        });
      },
    });
  };

  const fetchAlerts = () => {
    API({
      ...getAlerts,
      onSuccess: (res) => {
        setAlerts(res?.data?.data || []);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response?.data?.message}`,
        });
      },
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "home",
      })
    );
    navigate("/");
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleStatusChange = (alertId, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to ${
        status ? "activate" : "deactivate"
      } this alert.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, continue",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        API({
          ...updateAlertStatus,
          body: { id: alertId, alert_status: status ? 1 : 0 },
          onSuccess: (res) => {
            Swal.fire({
              icon: "success",
              title: `${res.data.message}`,
            });
            fetchAlerts();
          },
          onError: (error) => {
            Swal.fire({
              icon: "error",
              title: `${
                error.response?.data?.message || "Something went wrong!"
              }`,
            });
          },
        });
      }
    });
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  const isFormValid = alertMessage.length > 0 && alertType !== null;

  return (
    <>
      <Header />
      <div className="bg-worker overflow-y-auto my-2 flex-grow-1 w-100 mx-auto px-5 py-4 me-2 position-relative">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex justify-content-between align-items-center pb-3"
        >
          <h2
            className="mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Add Alert
          </h2>
          <Button style={{ marginRight: "40px" }} onClick={toggleModal}>
            Saved Alerts
          </Button>
        </div>

        <div className="mt-4 col-6">
          <InputForm
            type="text"
            id="alert_message"
            name="alert_message"
            placeholder="Alert Message"
            value={alertMessage}
            onChange={handleAlertMessageChange}
          />
          <small style={{ fontSize: "13px", color: "rgba(0, 0, 0, 0.6)" }}>
            {`Characters: ${alertMessage.length}/150`}
          </small>
        </div>
        <div className="mt-4 col-6">
          <label
            style={{
              marginTop: "4px",
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontFamily: "Montserrat",
            }}
            htmlFor="alert_type"
            className="form-label ms-2"
          >
            Alert Type
          </label>
          <Select
            options={alertOptions}
            id="alert_type"
            name="alert_type"
            placeholder="Select Alert Type"
            value={alertType}
            onChange={(selectedOption) => setAlertType(selectedOption)}
            isSearchable
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: "rgba(255, 231, 159,0.4)",
                padding: "2px 6px",
                borderRadius: "10px",
                fontFamily: "Montserrat",
                border: "none",
                boxShadow: "none",
              }),
            }}
          />
        </div>
        <div className="mt-4 d-flex justify-content-between col-6">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button
            onClick={onSubmit}
            type="submit"
            disabled={!isFormValid || loading}
            style={{
              opacity: !isFormValid || loading ? 0.6 : 1,
              pointerEvents: !isFormValid || loading ? "none" : "auto",
            }}
          >
            {loading ? "Loading..." : "Add"}
          </Button>
        </div>

        {modalVisible && (
          <div className="modal-overlay">
            <div className="modal-content">
              <div className="modal-header">
                <h3 style={{ fontFamily: "Montserrat", fontSize: "20px" }}>
                  Saved Alerts
                </h3>
                <button className="modal-close-btn" onClick={toggleModal}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="custom-table-container">
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {alerts.length > 0 ? (
                        alerts.map((alert, index) => (
                          <tr key={index}>
                            <td>{alert.id}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={alert.alert_status === true}
                                onChange={() =>
                                  handleStatusChange(
                                    alert.id,
                                    !alert.alert_status
                                  )
                                }
                              />
                            </td>
                            <td>{alert.alert_type}</td>
                            <td>{alert.alert_message}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="no-alerts">
                            No alerts found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <style>
        {`
          /* Modal Styles */
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .modal-content {
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            max-width: 80%;
            max-height: 80%;
            overflow-y: auto;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          }

          .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
          }

          .modal-close-btn {
            background-color: transparent;
            border: none;
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
            color: #333;
          }

          .modal-close-btn:hover {
            color: #f00;
          }

          .modal-body {
            margin-bottom: 20px;
            overflow-y: auto;
            max-height: 300px;
          }

          .custom-table {
            width: 100%;
            border-collapse: collapse;
          }

          .custom-table th, .custom-table td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
          }

          .custom-table th {
            background-color: #f4f4f4;
            font-weight: bold;
          }

          .no-alerts {
            text-align: center;
            color: #888;
            font-style: italic;
          }
        `}
      </style>
    </>
  );
};

export default AddAlert;
