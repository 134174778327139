import React, { useEffect, useRef, useState } from "react";
import { FaDownload } from "react-icons/fa";
import shareTicket from "../../../../images/services/travel/shareTicket.svg";
import { sourceToDest } from "../../../../images";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { QRCodeCanvas } from "qrcode.react";
import {
  cancelTicket,
  cancelTicketData,
  checkBookedTicket,
} from "../../../../urls";
import API from "../../../../components/api2";
import Swal from "sweetalert2";
import loaderGif from "../../../../gif/buffer.gif";

const ViewTicket = ({ data }) => {
  const ticketRef = useRef();
  const [ticketData, setTicketData] = useState(null);

  const styles = {
    container: {
      width: "400px",
      margin: "0 auto 20px auto",
      padding: "20px",
      border: "1px solid #ddd",
      borderRadius: "10px",
      fontFamily: "Montserrat, sans-serif",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    headerTitle: {
      margin: "0",
      fontWeight: "bold",
      fontSize: "20px",
      color: "#194280",
    },
    headerSubtitle: {
      fontSize: "14px",
      color: "#4F575B",
    },
    destinations: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    destinationTitle: {
      margin: "0",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#194280",
    },
    details: {
      marginBottom: "20px",
      fontSize: "15px",
    },
    detailItem: {
      margin: "5px 0",
      color: "#50555C",
      fontWeight: "500",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    qrContainer: {
      textAlign: "center",
      marginBottom: "20px",
    },
    downloadButton: {
      backgroundColor: "#F97C4B",
      border: "none",
      color: "#fff",
      padding: "8px 10px",
      borderRadius: "5px",
      fontSize: "14px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 auto",
    },
    downloadIcon: {
      marginLeft: "8px",
    },
    cancelButtonContainer: {
      textAlign: "center",
    },
    cancelButton: {
      backgroundColor: "transparent",
      border: "none",
      color: "red",
      fontSize: "14px",
      cursor: "pointer",
    },
  };

  useEffect(() => {
    API({
      ...checkBookedTicket,
      body: { refid: data?.sr_no },
      onSuccess: (res) => {
        if (res?.data) {
          setTicketData(res.data.data);
        }
      },
      onError: (error) => {
        console.error("Error fetching ticket data:", error);
      },
    });
  }, [data?.sr_no]);

  const downloadPDF = async () => {
    const element = ticketRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("ticket.pdf");
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Bus Ticket",
          text: "Check out my ticket details for the trip.",
          url: window.location.href,
        })
        .then(() => console.log("Ticket shared successfully"))
        .catch((error) => console.log("Error sharing ticket:", error));
    } else {
      alert("Sharing is not supported in your browser.");
    }
  };

  const qrData = JSON.stringify({
    bookingDate: "10-9-2024",
    timeAndDate: "10:20am & 3:45 PM",
    busNumber: "RDF478",
    seatNumbers: "C2, C3",
    passengerName: "Yash",
    boardingPoint: "Alambagh",
    stoppingPoint: "Kashmiri Gate",
  });
  const handleCancel = () => {
    const seats = data?.seat_number?.split(", ") || [];

    Swal.fire({
      title: "Fetching Cancellation Details...",
      text: "Please wait while we fetch the details.",
      didOpen: () => {
        Swal.showLoading();
        API({
          ...cancelTicketData,
          body: { refid: data?.sr_no },
          onSuccess: (res) => {
            Swal.close();
            if (res?.data?.data) {
              const {
                cancellable,
                cancellationCharges,
                fares,
                totalRefundAmount,
              } = res.data.data;

              if (cancellable === "true") {
                Swal.fire({
                  title: "Cancellation Details",
                  html: `
                    <p><strong>Total Fare:</strong> ₹${
                      fares?.entry?.value || 0
                    }</p>
                    <p><strong>Cancellation Charges:</strong> ₹${
                      cancellationCharges?.entry?.value || 0
                    }</p>
                    <p><strong>Refund Amount:</strong> ₹${
                      totalRefundAmount || 0
                    }</p>
                    <p>Select the seats you want to cancel:</p>
                    <div id="seat-checkbox-container">
                      ${seats
                        .map(
                          (seat) => `
                          <div>
                            <input type="checkbox" id="${seat}" value="${seat}" />
                            <label for="${seat}">${seat}</label>
                          </div>
                        `
                        )
                        .join("")}
                    </div>
                    <p>Are you sure you want to cancel the selected seats?</p>
                  `,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Cancel",
                  cancelButtonText: "No",
                  preConfirm: () => {
                    const selectedCheckboxes = Array.from(
                      document.querySelectorAll(
                        "#seat-checkbox-container input[type='checkbox']:checked"
                      )
                    );
                    const selectedSeatNumbers = selectedCheckboxes.map(
                      (checkbox) => checkbox.value
                    );

                    if (selectedSeatNumbers.length === 0) {
                      Swal.showValidationMessage(
                        "Please select at least one seat to cancel."
                      );
                      return false;
                    }

                    return selectedSeatNumbers;
                  },
                }).then((result) => {
                  if (result.isConfirmed && result.value) {
                    const seatsToCancel = result.value;

                    Swal.fire({
                      title: "Processing Cancellation",
                      text: "Please wait...",
                      allowOutsideClick: false,
                      didOpen: () => {
                        Swal.showLoading();

                        API({
                          ...cancelTicket,
                          body: {
                            refid: data?.sr_no,
                            seatsToCancel,
                          },
                          onSuccess: (res) => {
                            Swal.fire(
                              "Cancelled!",
                              `${res?.data?.message}`,
                              "success"
                            );
                          },
                          onError: (error) => {
                            Swal.fire(
                              "Error!",
                              `${
                                error.response?.data?.message || "Unknown error"
                              }`,
                              "error"
                            );
                          },
                        });
                      },
                    });
                  }
                });
              } else {
                Swal.fire(
                  "Not Cancellable",
                  "This ticket cannot be cancelled.",
                  "info"
                );
              }
            }
          },
          onError: (error) => {
            Swal.fire(
              "Error",
              `${error.response?.data?.message || "Unable to fetch details"}`,
              "error"
            );
          },
        });
      },
    });
  };
  if (!ticketData) {
    return (
      <img
        src={loaderGif}
        alt="Loading..."
        style={{
          height: "60px",
          position: "absolute",
          left: "50%",
          top: "50%",
        }}
      />
    );
  }

  const dateOfIssue = ticketData?.dateOfIssue;
  let formattedDate = "";
  let formattedTime = "";

  if (dateOfIssue) {
    const dateObj = new Date(dateOfIssue);
    formattedDate = dateObj.toLocaleDateString("en-IN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    formattedTime = dateObj.toLocaleTimeString("en-IN", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  return (
    <div style={styles.container} ref={ticketRef}>
      {/* Header */}
      <div className="d-flex justify-content-between align-items-start mb-2">
        <div>
          <h4 style={styles.headerTitle}>
            {ticketData?.travels || "Bus Name"}
          </h4>
          <p style={styles.headerSubtitle}>
            {ticketData?.busType || "Bus Type Not Specified"}
          </p>
        </div>
        <img
          className="mt-2"
          style={{ width: "20px", cursor: "pointer", pointerEvents: "all" }}
          src={shareTicket}
          alt="share-ticket"
          onClick={handleShare}
        />
      </div>

      {/* Destinations */}
      <div style={styles.destinations}>
        <h5 style={styles.destinationTitle}>
          {ticketData?.sourceCity || "Source"}
        </h5>
        <img src={sourceToDest} alt="source-to-dest" />
        <h5 style={styles.destinationTitle}>
          {ticketData?.destinationCity || "Destination"}
        </h5>
      </div>

      {/* Details */}
      <div style={styles.details}>
        <div style={styles.detailItem}>
          <p className="mb-0">Booking Date:</p>
          <p className="mb-0">{formattedDate}</p>
        </div>
        <div style={styles.detailItem}>
          <p className="mb-0">Booking Time:</p>
          <p className="mb-0">{formattedTime}</p>
        </div>
        <div style={styles.detailItem}>
          <p className="mb-0">PNR Number:</p>
          <p className="mb-0">{ticketData?.pnr || "N/A"}</p>
        </div>
        <div style={styles.detailItem}>
          <p className="mb-0">Seat Number:</p>
          <p className="mb-0">{ticketData?.inventoryItems?.seatName}</p>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h2 style={{ fontSize: "16px", fontWeight: "600" }}>
            Boarding Point
          </h2>
          <p style={{ fontSize: "15px", fontWeight: "500", color: "#50555C" }}>
            {ticketData?.pickupLocation}
          </p>
        </div>
        <div>
          <h2 style={{ fontSize: "16px", fontWeight: "600" }}>
            Dropping Point
          </h2>
          <p style={{ fontSize: "15px", fontWeight: "500", color: "#50555C" }}>
            {ticketData?.dropLocation}
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        {/* QR Code */}
        <div style={styles.qrContainer}>
          <QRCodeCanvas value={qrData} size={100} />
        </div>

        {/* Action Buttons */}
        <div>
          <button style={styles.downloadButton} onClick={downloadPDF}>
            Download Ticket
            <FaDownload style={styles.downloadIcon} />
          </button>
        </div>
      </div>

      {/* Cancel Button */}
      <div style={styles.cancelButtonContainer}>
        <button onClick={handleCancel} style={styles.cancelButton}>
          Cancel Ticket
        </button>
      </div>
    </div>
  );
};

export default ViewTicket;
