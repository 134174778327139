import React, { useEffect, useState } from "react";
import { getMenus, getMenusDropdown } from "../urls";
import API from "../components/api2";
import { circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import Filter2 from "../components/Filter2";

const MenuList = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 14 });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchMenus({ initial: true });
    fetchMenusDropdown();
  }, []);

  async function fetchMenuExcel({ filterValues = {} } = {}) {
    return await new Promise((resolve, reject) => {
      fetchMenus({
        filterValues: filterValues,
        resolve: resolve,
        reject: reject,
      });
    });
  }

  const fetchMenusDropdown = () => {
    API({
      ...getMenusDropdown,
      onSuccess: (res) => {
        setDropdown(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching menus dropdown: ", error);
      },
    });
  };
  const fetchMenus = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }
    API({
      ...getMenus,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          // setActive(res.data.data.active_count);
          // setInActive(res.data.data.inactive_count);
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [...prev, ...res.data.data.menu_data]);
            } else {
              setFilteredTable(res.data.data.menu_data);
              setTotal(res.data.data.total_rows);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error("Error fetching menus: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "newMenu",
      })
    );

    navigate("/");
  };
  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchMenus({ append: true });
    }
  }, [pagination]);

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            List of Menus
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchMenus}
          excelFunc={fetchMenuExcel}
          loading={loading}
          excelFileName={"Menus Data"}
          filters={[
            {
              placeholder: "Menu Name",
              id: "menu_name",
              filterType: "select",
              inputType: null,
              options: dropdown?.map((item) => ({
                value: item.menu_name,
                label: item.menu_name,
              })),
            },
          ]}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={changePageFunc}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD MENU"}
            </p>
          </div>
        </Filter2>

        <PaginationTable
          columns={[
            { Header: "Menu ID", accessor: "menu_id" },
            { Header: "Menu Name", accessor: "menu_name" },
            {
              Header: "Created On",
              accessor: "created_on",
              Cell: ({ value }) => {
                const dateObject = new Date(value);
                const formattedDate = dateObject.toISOString().split("T")[0];
                return formattedDate;
              },
            },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
          totalRows={total}
        />
      </div>
    </>
  );
};

export default MenuList;
