import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFees,
  updateMerchant,
  verifyMerch,
  getMerchant,
  addComments,
  getComments,
} from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { circles, noImage2, pdfViewIcon } from "../images";
import Button from "../components/Button";
import { changePage } from "../redux/slice/dashboardSlice";
import { connect, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Alert from "../components/Alert";
import ImageViewer from "../components/ImageViewer";
import Payment from "../components/Payment";

const MerchantAppInfo = ({ roles }) => {
  const location = useLocation();
  const { service } = location.state || {};
  const [fee, setFee] = useState("");
  const [showAlertReject, setShowAlertReject] = useState(false);
  const [showAlertApprove, setShowAlertApprove] = useState(false);
  const [showAlertUpdate, setShowAlertUpdate] = useState(false);
  const [alertTextReject, setAlertTextReject] = useState("");
  const [alertTextApprove, setAlertTextApprove] = useState("");
  const [alertTextUpdate, setAlertTextUpdate] = useState("");
  const [walletColor, setWalletColor] = useState(false);
  const [merchantApp, setMerchantApp] = useState({});
  const [comments, setComments] = useState([]);
  const [showPaymentComponent, setShowPaymentComponent] = useState(false);
  const [appId, setAppId] = useState("");
  const [loading, setLoading] = useState(false);
  const [editableFields, setEditableFields] = useState({
    regional_distributer_id: "",
    area_distributer_id: "",
    local_distributer_id: "",
    merchant_type: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    pan_number: "",
    gst_number: "",
    aadhar_number: "",
    contact_no: "",
    residential_mailing_address: "",
    residential_mailing_city: "",
    residential_mailing_state: "",
    residential_mailing_pin: "",
    kyc_contact_person: "",
    kyc_contact_email: "",
    kyc_contact_no: "",
    kyc_mailing_address: "",
    kyc_mailing_city: "",
    kyc_mailing_state: "",
    kyc_mailing_pin: "",
    business_mailing_address: "",
    business_mailing_city: "",
    business_mailing_state: "",
    business_mailing_pin: "",
    allow_create_business_scheme: "",
    allow_commission_structure: "",
    allow_working_as_agent: "",
    created_on: "",
    created_by: "",
    updated_on: "",
    updated_by: "",
    additional_info: "",
    active_status: "",
    status: "",
    business_name: "",
    legal_name: "",
    age: "",
    education: "",
    photo_doc_url: "",
    pan_doc_url: "",
    gst_doc_url: "",
    aadhar_doc: "",
    shop_photo_url: "",
    address_proof_doc: "",
    gender: "",
    contact_person: "",
    contact_email: "",
    business_area: "",
    shop_area: "",
    finger_print_device: "",
    micro_atm_device: "",
    other_devices: "",
  });
  const [rolesToSend, setRolesToSend] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // alert
  const hideAlert = () => {
    setShowAlertReject(false);
    setShowAlertApprove(false);
    setShowAlertUpdate(false);
  };
  const showAlertWithTextReject = (text, walletColor) => {
    setAlertTextReject(text);
    setShowAlertReject(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextApprove = (text, walletColor) => {
    setAlertTextApprove(text);
    setShowAlertApprove(true);
    setWalletColor(walletColor);
  };
  const showAlertWithTextUpdate = (text, walletColor) => {
    setAlertTextUpdate(text);
    setShowAlertUpdate(true);
    setWalletColor(walletColor);
  };
  const handleClickReject = () => {
    showAlertWithTextReject("Are you sure you want to", "red");
  };
  const handleClickApprove = () => {
    showAlertWithTextApprove("Are you sure you want to", "green");
  };
  const handleClickUpdate = () => {
    showAlertWithTextUpdate("Are you sure you want to", "green");
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allMerchantApplication",
      })
    );

    navigate("/");
  };

  useEffect(() => {
    fetchFeeDetails();
    getSelectedMerchant();
    fetchComments();
  }, []);

  const fetchFeeDetails = () => {
    API({
      ...getFees,
      params: {
        application_no: service?.application_number,
        application_type: "MERCHANT",
      },
      onSuccess: (res) => {
        setFee(res?.data?.data[0]);
        setAppId(res?.data?.data[0].application_no);
      },
      onError: (error) => {
        console.log("error");
      },
    });
  };

  const handlePayNowClick = () => {
    setShowPaymentComponent(true);
  };

  const getSelectedMerchant = () => {
    API({
      ...getMerchant,
      params: {
        application_number: service?.application_number,
      },
      onSuccess: (res) => {
        setMerchantApp(res?.data?.data);
        setEditableFields(res?.data?.data);
      },
      onError: (error) => {
        console.log("error");
      },
    });
  };
  const handleInputChange = (fieldName, value) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  // application verification
  const handleSendBack = () => {
    setLoading(true);
    API({
      ...verifyMerch,
      body: {
        application_number: service.application_number,
        verify_check: "S",
      },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  // update
  const handleConfirmUpdate = () => {
    setLoading(true);
    const { created_by, created_on, updated_by, updated_on, ...updateData } =
      editableFields;
    API({
      ...updateMerchant,
      body: updateData,
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertUpdate(false);
  };

  // reject
  const handleConfirmReject = () => {
    setLoading(true);
    API({
      ...verifyMerch,
      body: {
        application_number: service.application_number,
        verify_check: "R",
      },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertReject(false);
  };

  // approve
  const handleConfirmApprove = () => {
    setLoading(true);
    API({
      ...verifyMerch,
      body: {
        application_number: service?.application_number,
        verify_check: "A",
      },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertApprove(false);
  };

  // comments
  const fetchComments = () => {
    API({
      ...getComments,
      params: {
        application_no: service?.application_number,
        user_type: "MERCHANT",
      },
      onSuccess: (res) => {
        setComments(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching comments: ", error);
      },
    });
  };
  const handleAddComment = () => {
    const comment = document.getElementById("comments").value;
    if (!comment.trim()) {
      Swal.fire({
        icon: "error",
        title: `comment cannot be empty`,
      });
      return;
    }
    API({
      ...addComments,
      body: {
        application_no: merchantApp?.application_number,
        user_type: "MERCHANT",
        comments: comment,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        console.error("Error adding comment: ", error);
      },
    });
  };

  // format date and time
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  };

  const { active_status } = merchantApp;

  const hasRole = (role) => roles.includes(role);

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-4 mb-0 px-3"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Merchant Application
        </h2>

        {/* contact details  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-3 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Contact Details
          </h3>
          <div className="row align-items-center mt-4 px-5">
            <div className="col-8">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Mobile No
                </p>
                <input
                  type="text"
                  name="contact_no"
                  className="p-2 w-75 mt-1"
                  value={editableFields.contact_no}
                  onChange={(e) =>
                    handleInputChange("contact_no", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={10}
                />
                <p
                  className="mb-0 mt-4"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Email id
                </p>

                <input
                  type="text"
                  name="contact_email"
                  className="p-2 w-75 mt-1"
                  value={editableFields.contact_email}
                  onChange={(e) =>
                    handleInputChange("contact_email", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
            </div>
            <div className="col-4">
              <div
                className="text-center"
                style={{
                  border: "1.5px solid rgba(217, 217, 217, 1)",
                  borderRadius: "22px",
                }}
              >
                <img
                  className="w-100"
                  style={{ height: "150px" }}
                  src={merchantApp.photo_doc_url}
                  alt="demo"
                />
              </div>
            </div>
          </div>
        </div>

        {/* kyc  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            KYC Details
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="row">
              <div className="col-6">
                <div>
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    PAN
                  </p>

                  <input
                    type="text"
                    name="pan_number"
                    className="p-2 w-75 mt-1"
                    value={editableFields.pan_number}
                    onChange={(e) =>
                      handleInputChange("pan_number", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    maxLength={10}
                  />
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    GSTIN
                  </p>
                  <input
                    type="text"
                    name="gst_number"
                    className="p-2 w-75 mt-1"
                    value={editableFields.gst_number}
                    onChange={(e) =>
                      handleInputChange("gst_number", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    maxLength={15}
                  />
                </div>
              </div>
              <div className="col-6">
                <div>
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Aadhar
                  </p>
                  <input
                    type="text"
                    name="aadhar_number"
                    className="p-2 w-75 mt-1"
                    value={editableFields.aadhar_number}
                    onChange={(e) =>
                      handleInputChange("aadhar_number", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                    maxLength={12}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* personal details  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Personal Details
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="row">
              <div className="col-6">
                <div>
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    First Name
                  </p>
                  <input
                    type="text"
                    name="first_name"
                    className="p-2 w-75 mt-1"
                    value={editableFields.first_name}
                    onChange={(e) =>
                      handleInputChange("first_name", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Last Name
                  </p>
                  <input
                    type="text"
                    name="last_name"
                    className="p-2 w-75 mt-1"
                    value={editableFields.last_name}
                    onChange={(e) =>
                      handleInputChange("last_name", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Date of Birth
                  </p>
                  <input
                    type="date"
                    name="age"
                    className="p-2 w-75 mt-1"
                    value={editableFields.age}
                    onChange={(e) => handleInputChange("age", e.target.value)}
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div>
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Middle Name
                  </p>
                  <input
                    type="text"
                    name="middle_name"
                    className="p-2 w-75 mt-1"
                    value={editableFields.middle_name}
                    onChange={(e) =>
                      handleInputChange("middle_name", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Gender
                  </p>
                  <select
                    name="gender"
                    className="p-2 w-75 mt-1"
                    value={editableFields.gender}
                    onChange={(e) =>
                      handleInputChange("gender", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                      border: "0",
                    }}
                  >
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                    <option value="TRANSGENDER">TRANSGENDER</option>
                  </select>
                </div>
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Education
                  </p>

                  <select
                    name="education"
                    className="p-2 w-75 mt-1"
                    value={editableFields.education}
                    onChange={(e) =>
                      handleInputChange("education", e.target.value)
                    }
                    style={{
                      backgroundColor: "rgba(255, 231, 159, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                      border: "0",
                    }}
                  >
                    <option value="10th">10th</option>
                    <option value="12th">12th</option>
                    <option value="graduate">Graduate</option>
                    <option value="post_graduate">Post-Graduate</option>
                    <option value="phd">PH.D</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* mailing address  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Mailing Address
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Address
                </p>
                <input
                  type="text"
                  name="residential_mailing_address"
                  className="p-2 w-75 mt-1"
                  value={editableFields.residential_mailing_address}
                  onChange={(e) =>
                    handleInputChange(
                      "residential_mailing_address",
                      e.target.value
                    )
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  City
                </p>
                <input
                  type="text"
                  name="residential_mailing_city"
                  className="p-2 w-75 mt-1"
                  value={editableFields.residential_mailing_city}
                  onChange={(e) =>
                    handleInputChange(
                      "residential_mailing_city",
                      e.target.value
                    )
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
            </div>
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  State
                </p>
                <input
                  type="text"
                  name="residential_mailing_state"
                  className="p-2 w-75 mt-1"
                  value={editableFields.residential_mailing_state}
                  onChange={(e) =>
                    handleInputChange(
                      "residential_mailing_state",
                      e.target.value
                    )
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  PIN
                </p>
                <input
                  type="text"
                  name="residential_mailing_pin"
                  className="p-2 w-75 mt-1"
                  value={editableFields.residential_mailing_pin}
                  onChange={(e) =>
                    handleInputChange("residential_mailing_pin", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={6}
                />
              </div>
            </div>
          </div>
        </div>

        {/* permanent address  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Permanent Address
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Address
                </p>
                <input
                  type="text"
                  name="kyc_mailing_address"
                  className="p-2 w-75 mt-1"
                  value={editableFields.kyc_mailing_address}
                  onChange={(e) =>
                    handleInputChange("kyc_mailing_address", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  City
                </p>
                <input
                  type="text"
                  name="kyc_mailing_city"
                  className="p-2 w-75 mt-1"
                  value={editableFields.kyc_mailing_city}
                  onChange={(e) =>
                    handleInputChange("kyc_mailing_city", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
            </div>
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  State
                </p>
                <input
                  type="text"
                  name="kyc_mailing_state"
                  className="p-2 w-75 mt-1"
                  value={editableFields.kyc_mailing_state}
                  onChange={(e) =>
                    handleInputChange("kyc_mailing_state", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  PIN
                </p>
                <input
                  type="text"
                  name="kyc_mailing_pin"
                  className="p-2 w-75 mt-1"
                  value={editableFields.kyc_mailing_pin}
                  onChange={(e) =>
                    handleInputChange("kyc_mailing_pin", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={6}
                />
              </div>
            </div>
          </div>
        </div>

        {/* business details  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Business Details
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Business Name
                </p>
                <input
                  type="text"
                  name="business_name"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_name}
                  onChange={(e) =>
                    handleInputChange("business_name", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Shop Name
                </p>
                <input
                  type="text"
                  name="shop_area"
                  className="p-2 w-75 mt-1"
                  value={editableFields.shop_area}
                  onChange={(e) =>
                    handleInputChange("shop_area", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Legal Name
                </p>
                <input
                  type="text"
                  name="legal_name"
                  className="p-2 w-75 mt-1"
                  value={editableFields.legal_name}
                  onChange={(e) =>
                    handleInputChange("legal_name", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
            </div>
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Business Area
                </p>
                <input
                  type="text"
                  name="business_area"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_area}
                  onChange={(e) =>
                    handleInputChange("business_area", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  GSTIN
                </p>
                <input
                  type="text"
                  name="gst_number"
                  className="p-2 w-75 mt-1"
                  value={editableFields.gst_number}
                  onChange={(e) =>
                    handleInputChange("gst_number", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={15}
                />
              </div>
            </div>
          </div>
        </div>

        {/* business communication  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Business Communication
          </h3>
          <div className="row d-flex justify-content-between mt-4 px-5">
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Address
                </p>
                <input
                  type="text"
                  name="business_mailing_address"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_mailing_address}
                  onChange={(e) =>
                    handleInputChange(
                      "business_mailing_address",
                      e.target.value
                    )
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  City
                </p>
                <input
                  type="text"
                  name="business_mailing_city"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_mailing_city}
                  onChange={(e) =>
                    handleInputChange("business_mailing_city", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
            </div>
            <div className="col-6">
              <div>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  State
                </p>
                <input
                  type="text"
                  name="business_mailing_state"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_mailing_state}
                  onChange={(e) =>
                    handleInputChange("business_mailing_state", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  PIN
                </p>
                <input
                  type="text"
                  name="business_mailing_pin"
                  className="p-2 w-75 mt-1"
                  value={editableFields.business_mailing_pin}
                  onChange={(e) =>
                    handleInputChange("business_mailing_pin", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={6}
                />
              </div>
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Contact No
                </p>
                <input
                  type="text"
                  name="contact_no"
                  className="p-2 w-75 mt-1"
                  value={editableFields.contact_no}
                  onChange={(e) =>
                    handleInputChange("contact_no", e.target.value)
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  }}
                  maxLength={10}
                />
              </div>
            </div>
          </div>
        </div>

        {/* fee details  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Payment Detail
          </h3>
          {fee &&
          fee.tran_date &&
          fee.transaction_amount &&
          fee.payment_mode ? (
            <div className="row d-flex justify-content-between mt-4 px-5">
              <div className="col-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Payment Date
                </p>
                <p
                  className="mb-0 mt-1"
                  style={{
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                  }}
                >
                  {fee.tran_date ? fee.tran_date : ""}
                </p>
              </div>
              <div className="col-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Payment Amount
                </p>
                <p
                  className="mb-0 mt-1"
                  style={{
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                  }}
                >
                  {fee.transaction_amount}
                </p>
              </div>
              <div className="col-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Payment Mode
                </p>
                <p
                  className="mb-0 mt-1"
                  style={{
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                  }}
                >
                  {fee.payment_mode}
                </p>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-4">
              <button
                style={{
                  backgroundColor: "#194280",
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "14px",
                  border: "none",
                  padding: "6px 12px",
                  borderRadius: "5px",
                }}
                onClick={handlePayNowClick}
              >
                Pay Now
              </button>
            </div>
          )}
          {showPaymentComponent && (
            <Payment
              applicationType="MERCHANT"
              appId={service?.application_number}
            />
          )}
        </div>

        {/* Attachment */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Attachment
          </h3>
          <div className="row mx-4 mt-4">
            <div className="col-3">
              <div
                className="position-relative pdf-hvr"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "160px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  GST Certificate
                </p>
                <ImageViewer
                  thumbnailHeight="120px"
                  className="w-100 px-3 py-2"
                  src={
                    merchantApp.gst_doc_url === null
                      ? noImage2
                      : merchantApp?.gst_doc_url
                  }
                  alt="gst_docs"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="position-relative pdf-hvr "
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "160px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  PAN Certificate
                </p>
                <ImageViewer
                  thumbnailHeight="120px"
                  className="w-100 px-3 py-2"
                  src={merchantApp?.pan_doc_url}
                  alt="pan_docs"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="position-relative pdf-hvr"
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  Aadhar Certificate
                </p>
                <ImageViewer
                  thumbnailHeight="120px"
                  className="w-100 px-3 py-2"
                  src={merchantApp?.aadhar_doc}
                  alt="aadhar_doc"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="position-relative pdf-hvr "
                style={{
                  backgroundColor: "rgba(26, 25, 24, 0.5)",
                  borderRadius: "10px",
                  height: "160px",
                  cursor: "pointer",
                }}
              >
                <img
                  className="position-absolute"
                  src={pdfViewIcon}
                  alt="pdf-view"
                  style={{
                    left: "80%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: "0",
                  }}
                />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "white",
                  }}
                  className="mb-0 py-2 px-3"
                >
                  Shop Photo
                </p>
                <ImageViewer
                  thumbnailHeight="120px"
                  className="w-100 px-3 py-2"
                  src={merchantApp?.shop_photo_url}
                  alt="shop_photo_url"
                />
              </div>
            </div>
          </div>
        </div>

        {/* comment  */}
        <div className="mt-4 pb-3">
          <h3
            className="px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Comment
          </h3>
          <div
            className="mt-4 mx-5 px-5 py-3"
            style={{
              backgroundColor: "rgba(231, 232, 233, 1)",
              height: "260px",
              borderRadius: "14px",
              overflowY: "auto",
            }}
          >
            <div className="row d-flex justify-content-around">
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  User
                </p>
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Comment
                </p>
              </div>
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Commented on
                </p>
              </div>
            </div>
            {comments.map((comment) => (
              <div
                key={comment.comment_id}
                className="row d-flex justify-content-around"
              >
                <div className="col-3">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {comment.username}
                  </p>
                </div>
                <div className="col-6">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {comment.comments}
                  </p>
                </div>
                <div className="col-3">
                  <p
                    className="mb-0 mt-2"
                    style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                  >
                    {formatDate(comment.created_on)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="px-5">
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
              className="mb-0 mt-3 ms-3"
            >
              Comment
            </p>
            <div
              style={{
                backgroundColor: "#FFF6D8",
                borderRadius: "14px",
                border: "1.5px solid #D9D9D9",
              }}
            >
              <div className="d-flex align-items-center py-2">
                <textarea
                  className="w-100 px-3"
                  style={{
                    outline: "none",
                    backgroundColor: "#FFF6D8",
                    resize: "none",
                    border: "none",
                    borderRadius: "14px",
                  }}
                  name="comments"
                  placeholder="Write"
                  id="comments"
                  rows="4"
                ></textarea>
                <button
                  style={{
                    borderRadius: "24px",
                    backgroundColor: "#FFF6D8",
                    border: "1px solid black",
                  }}
                  className="mx-3 px-3 py-1"
                  onClick={handleAddComment}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* buttons  */}
        <div className="mt-5 d-flex align-items-center justify-content-between px-5">
          <Button onClick={changePageFunc} children={"Back"}></Button>
          <div>
            {hasRole("MERCHANT_APP_EDIT") && active_status === "S" && (
              <button
                className="py-1 px-2 ms-2"
                style={{
                  border: "1px solid rgba(22, 64, 127, 1)",
                  borderRadius: "5px",
                  fontFamily: "Monteserrat",
                  color: "rgba(79, 87, 91, 1)",
                  fontWeight: "500",
                  fontSize: "14px",
                  opacity: loading ? "0.6" : "1",
                  width: "118px",
                }}
                onClick={handleClickUpdate}
                disabled={
                  active_status === "A" || active_status === "R" || loading
                }
              >
                {loading ? "Loading..." : "Update"}
              </button>
            )}
            <>
              {hasRole("MERCHANT_APP_SENDBACK") && (
                <button
                  className="py-1 px-2 ms-2"
                  style={{
                    border: "1px solid rgba(22, 64, 127, 1)",
                    borderRadius: "5px",
                    fontFamily: "Monteserrat",
                    color: "rgba(79, 87, 91, 1)",
                    opacity: loading ? "0.6" : "1",
                    fontWeight: "500",
                    fontSize: "14px",
                    width: "118px",
                  }}
                  onClick={handleSendBack}
                  disabled={
                    active_status === "A" || active_status === "R" || loading
                  }
                >
                  {active_status === "S" ? "Sent Back" : "Send Back"}
                </button>
              )}

              {hasRole("MERCHANT_APP_REJECT") && (
                <button
                  className="py-1 px-2 ms-2"
                  style={{
                    border: "1.5px solid rgba(98, 192, 140, 1)",
                    borderRadius: "5px",
                    fontFamily: "Monteserrat",
                    color: "rgba(249, 124, 75, 1)",
                    fontWeight: "500",
                    opacity: loading ? "0.6" : "1",
                    fontSize: "16px",
                    width: "118px",
                  }}
                  onClick={handleClickReject}
                  disabled={
                    active_status === "R" || active_status === "A" || loading
                  }
                >
                  {active_status === "R" ? "Rejected" : "Reject"}
                </button>
              )}

              {hasRole("MERCHANT_APP_APPROVE") && (
                <button
                  className="py-1 px-2 ms-2"
                  style={{
                    border: "1.5px solid rgba(98, 192, 140, 1)",
                    borderRadius: "5px",
                    fontFamily: "Monteserrat",
                    color: "white",
                    opacity: loading ? "0.6" : "1",
                    fontWeight: "500",
                    fontSize: "16px",
                    width: "118px",
                    backgroundColor: "rgba(98, 192, 140, 1)",
                  }}
                  onClick={handleClickApprove}
                  disabled={
                    active_status === "R" || active_status === "A" || loading
                  }
                >
                  {active_status === "A" ? "Approved" : "Approve"}
                </button>
              )}
            </>
            <Alert
              show={showAlertReject}
              onHide={hideAlert}
              title="Confirmation"
              actionText={"reject"}
              message={alertTextReject}
              term="Merchant"
              forTerm="application for"
              walletColor={walletColor}
              onConfirm={handleConfirmReject}
            />
            <Alert
              show={showAlertApprove}
              onHide={hideAlert}
              title="Confirmation"
              actionText={"approve"}
              message={alertTextApprove}
              term="Merchant"
              forTerm="application for"
              walletColor={walletColor}
              onConfirm={handleConfirmApprove}
            />
            <Alert
              show={showAlertUpdate}
              onHide={hideAlert}
              title="Confirmation"
              actionText={"update"}
              forTerm=" "
              message={alertTextUpdate}
              term="Merchant"
              walletColor={walletColor}
              onConfirm={handleConfirmUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  roles: state.user.roles,
});

export default connect(mapStateToProps)(MerchantAppInfo);
