import React, { useEffect, useState } from "react";
import { checkRechgStatusReport, rechgStatusReport } from "../urls";
import API from "../components/api2";
import Header from "../components/Header";
import { actionIcon, circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import Filter2 from "../components/Filter2";
import Swal from "sweetalert2";

const RechargeStatusReport = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 14 });
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [loadingRows, setLoadingRows] = useState({});

  const fetchData = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }
    const filteredObject = {};
    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }
    setFilters(filteredObject);
    API({
      ...rechgStatusReport,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          console.log("test");
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              setFilteredTable(res.data.data.table_data);
              setTotalRows(res.data.data.total_rows);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const handleCheckStatus = (sr_no) => {
    setLoadingRows((prev) => ({ ...prev, [sr_no]: true }));

    API({
      ...checkRechgStatusReport,
      body: { sr_no },
      onSuccess: (res) => {
        setLoadingRows((prev) => ({ ...prev, [sr_no]: false }));
        const updatedStatus = res.data.data.status;
        setFilteredTable((prev) =>
          prev.map((row) =>
            row.sr_no === sr_no ? { ...row, status: updatedStatus } : row
          )
        );
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoadingRows((prev) => ({ ...prev, [sr_no]: false }));
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchData({ append: true, filterValues: filters });
    }
  }, [pagination]);

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Recharge Status Report
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchData}
          loading={loading}
          showExcelIcon={false}
          filters={[
            {
              placeholder: "Recharge Type",
              id: "recharge_type",
              filterType: "select",
              inputType: null,
              options: [
                { value: "MOBILE", label: "MOBILE" },
                { value: "DTH", label: "DTH" },
              ],
            },
          ]}
        ></Filter2>
        <PaginationTable
          columns={[
            {
              Header: "Actions",
              accessor: "actions",
              Cell: ({ row }) => {
                const sr_no = row.original.sr_no;
                const isLoading = loadingRows[sr_no] || false;

                return (
                  <button
                    className="px-2 py-1 text-white border-0"
                    style={{
                      backgroundColor: "#194280",
                      borderRadius: "5px",
                      opacity: isLoading ? "0.6" : "1",
                      fontSize: "12px",
                    }}
                    onClick={() => handleCheckStatus(sr_no)}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Check Status"}
                  </button>
                );
              },
            },
            { Header: "Status", accessor: "status" },
            { Header: "Request ID", accessor: "recharge_req_id" },
            { Header: "SR No.", accessor: "sr_no" },
            { Header: "Recharge Type", accessor: "recharge_type" },
            { Header: "Mobile", accessor: "mobile_no" },
            { Header: "Operator", accessor: "operator" },
            { Header: "Recharge Amount", accessor: "recharge_amount" },
            { Header: "Created On", accessor: "created_on" },
            { Header: "Created By", accessor: "created_by" },
            { Header: "Acknowledge Number", accessor: "ackno" },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
          totalRows={totalRows}
        />
      </div>
    </>
  );
};

export default RechargeStatusReport;
