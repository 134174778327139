import React, { useEffect, useState } from "react";
import { minus, plus, warningIcon } from "../images";
import { getWallet, getProfile } from "../urls";
import Header from "../components/Header";
import SlickSlider from "../components/SlickSlider";
import ChartComponent from "../components/BarChart";
import TransactionDetails from "../components/TransactionDetails";
import API from "../components/api2";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changePage } from "../redux/slice/dashboardSlice";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Home = () => {
  const [wallet, setWallet] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchWallet();
  }, []);

  const fetchWallet = () => {
    API({
      ...getWallet,
      onSuccess: (res) => {
        setWallet(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching balance: ", error);
      },
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const changePageFunc = (e, service) => {
    let url;

    if (e.target.id) {
      url = e.target.id;
      dispatch(
        changePage({
          page: url,
        })
      );
    }

    navigate("/");
  };

  //   fetch profile details
  const fetchProfile = () => {
    return new Promise((resolve, reject) => {
      API({
        ...getProfile,
        onSuccess: (res) => {
          setProfileData(res.data.data);
          resolve(res.data.data);
        },
        onError: (error) => {
          console.error("Error fetching profile: ", error);
          reject(error);
        },
      });
    });
  };
  const handleClick = (event) => {
    fetchProfile()
      .then((data) => {
        if (data.employee_type === "MERCHANT") {
          changePageFunc(event);
        } else {
          Swal.fire({
            title: "Access Denied",
            text: "You are not authorized to perform this action.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        console.error("Fetch profile error: ", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while fetching profile data.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleAepsTransfer = () => {
    fetchProfile().then((data) => {
      if (data.employee_type === "MERCHANT") {
        dispatch(
          changePage({
            page: "aepsWalletTransfer",
          })
        );
        navigate("/");
      } else {
        Swal.fire({
          title: "Access Denied",
          text: "You are not authorized to perform this action.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  };

  return (
    <>
      <Header />
      <section
        className="overflow-y-auto overflow-x-hidden"
        style={{ backgroundColor: "#E7E8E9", height: "100%" }}
      >
        <div
          style={{ height: "30%" }}
          className="row d-flex align-items-center container-fluid mt-2 home_sec1"
        >
          <div
            className="col-6 pt-3"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <div className="row position-relative">
              <div className="col-6 text-center vert-line">
                <p
                  className="mb-0 home_text"
                  style={{
                    color: "rgba(79, 87, 91, 1)",
                    fontWeight: "500",
                    fontSize: "22px",
                  }}
                >
                  {t("Main Wallet")}
                </p>
                <div className="d-flex align-items-center mt-1 justify-content-center">
                  <p
                    className="mb-0 trans_text"
                    style={{
                      color: "rgba(79, 87, 91, 1)",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    {t("Available Balance")}
                  </p>
                  <img className="ms-2" src={warningIcon} alt="warning" />
                </div>
                <p
                  className="mb-0"
                  style={{
                    color: "rgba(79, 87, 91, 1)",
                    fontWeight: "500",
                    fontSize: "24px",
                  }}
                >
                  ₹ {wallet.closing_balance ? wallet.closing_balance : "0"}
                </p>
                <p
                  className="mb-0 trans_text"
                  style={{
                    color: "rgba(79, 87, 91, 0.5)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {t("we can withdraw money or add")}
                </p>
                <div
                  className="py-2 mx-auto d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "#194280",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    width: "150px",
                    cursor: "pointer",
                  }}
                >
                  {/* <img src={minus} alt="minus" /> */}
                  <p
                    className="mb-0 mx-3"
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: "white",
                    }}
                  >
                    ₹ {t("Fund")}
                  </p>
                  <div
                    id="rechargeMerchant"
                    onClick={handleClick}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={plus} alt="plus" />
                  </div>
                </div>
              </div>
              <div className="col-6 text-center">
                <p
                  className="mb-0 home_text"
                  style={{
                    color: "rgba(79, 87, 91, 1)",
                    fontWeight: "500",
                    fontSize: "22px",
                  }}
                >
                  {t("AEPS Wallet")}
                </p>
                <div className="d-flex align-items-center mt-1 justify-content-center">
                  <p
                    className="mb-0 trans_text"
                    style={{
                      color: "rgba(79, 87, 91, 1)",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    {t("Available Balance")}
                  </p>
                  <img className="ms-2" src={warningIcon} alt="warning" />
                </div>
                <p
                  className="mb-0"
                  style={{
                    color: "rgba(79, 87, 91, 1)",
                    fontWeight: "500",
                    fontSize: "24px",
                  }}
                >
                  ₹{" "}
                  {wallet.aeps_closing_balance
                    ? wallet.aeps_closing_balance
                    : "0"}
                </p>
                <p
                  className="mb-0 trans_text"
                  style={{
                    color: "rgba(79, 87, 91, 0.5)",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {t("we can withdraw money or add")}
                </p>

                <div
                  onClick={handleAepsTransfer}
                  className="py-2 mx-auto d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "#194280",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    width: "150px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ cursor: "pointer" }}>
                    <img src={minus} alt="minus" />
                  </div>

                  <p
                    className="mb-0 mx-3"
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: "white",
                    }}
                  >
                    ₹ {t("Fund")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <SlickSlider />
          </div>
        </div>
        <div
          style={{ height: "60%", marginTop: "30px" }}
          className="row home_sec2"
        >
          <div className="col-6 h-100">
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                height: "100%",
              }}
              className="p-3"
            >
              {" "}
              <ChartComponent />
            </div>
          </div>
          <div className="col-6 h-100">
            <TransactionDetails />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
