import React, { useEffect, useState } from "react";
import { getDesignation, designation } from "../../urls";
import API from "../../components/api2";
import { actionIcon, circles } from "../../images";
import PaginationTable from "../../components/PaginationTable";
import { changePage } from "../../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../../components/Header";
import Filter2 from "../../components/Filter2";

const ManageDesignation = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 14 });
  const [dropdown, setDropdown] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchDesig = ({
    filterValues = {},
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    setLoading(true);
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }
    API({
      ...getDesignation,
      params: { ...filteredObject },
      onSuccess: (res) => {
        setLoading(false);
        if (resolve) {
          resolve(res.data.data);
        } else {
          if (append) {
            setFilteredTable((prev) => [...prev, ...res.data.data]);
          } else {
            setFilteredTable(res.data.data);
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error("Error fetching designation: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  useEffect(() => {
    API({
      ...designation,
      onSuccess: (res) => {
        setDropdown(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching designation: ", error);
      },
    });
  }, []);

  const changePageFunc = ({ clickedService, targetPage }) => {
    if (clickedService) {
      dispatch(
        changePage({
          page: "editDesignation",
          service: clickedService,
        })
      );

      navigate("/", { state: { service: clickedService } });
    } else {
      dispatch(
        changePage({
          page: targetPage,
        })
      );
      navigate("/", {});
    }
  };

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            All Designations
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchDesig}
          loading={loading}
          filters={[
            {
              placeholder: "Designation ID",
              id: "designation_id",
              filterType: "input",
              inputType: "number",
            },
            {
              placeholder: "Designation Name",
              id: "designation_name",
              filterType: "select",
              inputType: null,
              options: dropdown?.map((item) => ({
                value: item.designation,
                label: item.designation,
              })),
            },
          ]}
          showExcelIcon={false}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              changePageFunc({ targetPage: "addDesignation" });
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD DESIGNATION"}
            </p>
          </div>
        </Filter2>

        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() =>
                      changePageFunc({ clickedService: row.original })
                    }
                  />
                );
              },
            },
            { Header: "Designation ID", accessor: "designation_id" },
            { Header: "Designation Name", accessor: "designation_name" },
          ]}
          data={filteredTable}
          skipPageReset={true}
          setPagination={setPagination}
        />
      </div>
    </>
  );
};

export default ManageDesignation;
