import React, { useState, useEffect } from "react";
import API from "../components/api2";
import { getAlerts } from "../urls";

const LoginAlerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const fetchAlerts = () => {
    API({
      ...getAlerts,
      onSuccess: (res) => {
        const alertMessages =
          res?.data?.data
            ?.filter(
              (alert) =>
                alert?.alert_type === "Login Screen" &&
                alert?.alert_status === true
            )
            ?.map((alert) => alert?.alert_message) || [];
        setAlerts(alertMessages);
        setIsVisible(true);
      },
      onError: (error) => {
        console.error("Error fetching alerts:", error);
      },
    });
  };

  useEffect(() => {
    fetchAlerts();

    const timer = setTimeout(() => {
      handleClose();
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      setIsVisible(false);
      setIsExiting(false);
    }, 500);
  };

  const containerStyle = {
    position: "fixed",
    top: "50%",
    left: isVisible && !isExiting ? "10%" : "-350px",
    transform: "translateY(-50%)",
    width: "350px",
    maxHeight: "300px",
    overflowY: "auto",
    background:
      "linear-gradient(90deg, rgba(2,0,36,1) 9%, rgba(255,6,6,0.48800770308123254) 64%, rgba(201,71,71,1) 98%)",
    color: "#fff",
    borderRadius: "8px",
    padding: "12px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
    zIndex: 10000,
    transition: "left 0.5s ease, opacity 0.5s ease",
    opacity: isExiting ? 0 : 1,
    pointerEvents: isVisible ? "all" : "none",
    animation: isVisible && !isExiting ? "bounce 1.5s infinite" : "none",
  };

  const alertStyle = {
    padding: "8px 12px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
    whiteSpace: "pre-wrap",
  };

  const titleStyle = {
    fontWeight: "bold",
    marginBottom: "8px",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "8px",
    right: "8px",
    background: "transparent",
    border: "none",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
  };

  const keyframesStyle = `
    @keyframes bounce {
      0%, 100% {
        transform: translateY(-50%);
      }
      50% {
        transform: translateY(calc(-50% - 10px));
      }
    }
  `;

  return (
    <>
      {/* Inline keyframes for animations */}
      <style>{keyframesStyle}</style>
      {isVisible && (
        <div style={containerStyle}>
          <button
            style={closeButtonStyle}
            onClick={handleClose}
            aria-label="Close"
          >
            ✕
          </button>
          <div style={titleStyle}>Notifications</div>
          {alerts.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              No notifications available
            </div>
          ) : (
            alerts.map((alert, index) => (
              <div key={index} style={alertStyle}>
                <strong>{index + 1}.</strong> {alert}
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default LoginAlerts;
